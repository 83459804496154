import React from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldPhone from '@giro/shared-components/Fields/FieldPhone.component';

import dialogChangeEmail from '../../store/dialogs/dialogChangeEmail';
import onboarding from '../../store/onboarding';

const DialogChangeEmailComponent = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    close: flow(dialogChangeEmail.action.close, dispatch),
  };

  const dispatchOnboarding = {
    updatePayload: flow(onboarding.action.updatePayload, dispatch),
    serviceGetCode: flow(onboarding.action.serviceGetCode, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogChangeEmail.selector.selectVisible),
  };

  const selectorOnboardingRedux = {
    payload: useSelector(onboarding.selector.selectPayload),
  };

  const handleClose = () => {
    dispatchRedux.close();
  };

  const initialValues = {
    email: '',
  };

  const schema = Yup.object({
    email: Yup.string().email(messages.EMAIL).required(messages.REQUIRED),
  });

  const handleSubmit = async (values) => {
    dispatchOnboarding.updatePayload({
      ...selectorOnboardingRedux.payload,
      ...values,
    });

    dispatchOnboarding.serviceGetCode('email');

    dispatchRedux.close();

    return true;
  };

  return (
    <Dialog open={selectorRedux.visible || false} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnMount
      >
        {({ errors, submitForm }) => (
          <Form>
            <DialogTitle>Atualizar o e-mail</DialogTitle>
            <DialogContent>
              <Box
                mt={3}
                minWidth={{
                  xs: 0,
                  md: 300,
                }}
              >
                <Field
                  fullWidth
                  component={TextField}
                  name="email"
                  label="E-mail"
                  type="email"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={Object.keys(errors).length > 0}
              >
                Atualizar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DialogChangeEmailComponent;
