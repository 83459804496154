import { flow } from 'lodash';
import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useDispatch } from 'react-redux';
import { hotjar } from 'react-hotjar';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import statusOnboardingConstant from '@giro/shared-constants/statusOnboarding.constant';

import useProtectedByAuthHook from './hooks/useProtectedByAuth.hook';
import useNotification from '@giro/shared-hooks/useNotification.hook';

import OnboardingScreen from './screens/Onboarding.screen';

import DialogChangePhone from './components/Dialogs/DialogChangePhone.component';
import DialogChangeEmail from './components/Dialogs/DialogChangeEmail.component';

import ROUTES from './constants/routes.constant';

import onboarding from './store/onboarding';
import apps from './store/apps';

const Routes = () => {
  const dispatch = useDispatch();
  const { user, signedIn } = useAuth();

  useProtectedByAuthHook();

  useNotification();

  const dispatchOnboardingRedux = {
    serviceGet: flow(onboarding.action.serviceGet, dispatch),
    fetchStart: flow(apps.action.fetchStart, dispatch),
  };

  useEffect(() => {
    dispatchOnboardingRedux.serviceGet();
    dispatchOnboardingRedux.fetchStart();
  }, [signedIn]);

  useEffect(() => {
    if (process.env.REACT_APP_HOTJAR === 'true') {
      hotjar.initialize(
        process.env.REACT_APP_HOTJAR_ID as any,
        process.env.REACT_APP_HOTJAR_SV as any
      );
    }
  }, []);

  useEffect(() => {
    if (signedIn && process.env.REACT_APP_HOTJAR === 'true') {
      const { user: userState } = user || {};

      if (hotjar.initialized()) {
        hotjar.identify(userState.id, {
          name: userState.name,
          email: userState.email,
        });
      }
    }
  }, [signedIn]);

  return (
    <>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={OnboardingScreen} />

        <Redirect to={ROUTES.ROOT} />
      </Switch>

      <DialogChangePhone />
      <DialogChangeEmail />
    </>
  );
};

export default Routes;
