import { flow } from 'lodash';

import { NAME } from './onboarding.constant';

const selectStateKey = (state) => state[NAME];
const selectDataKey = (state) => state.data;
const selectVariantKey = (state) => state.variant;
const selectPayloadKey = (state) => state.payload;
const selectLoadingKey = (state) => state.loading;
const selectErrorKey = (state) => state.error;
const selectStepKey = (state) => state.step;
const selectUserKey = (state) => state.user;

export const selectStateModule = flow(selectStateKey);
export const selectState = flow(selectStateModule);
export const selectPayload = flow(selectState, selectPayloadKey);
export const selectVariant = flow(selectState, selectVariantKey);
export const selectData = flow(selectState, selectDataKey);
export const selectLoading = flow(selectState, selectLoadingKey);
export const selectError = flow(selectState, selectErrorKey);
export const selectUser = flow(selectState, selectUserKey);
export const selectStep = flow(selectState, selectStepKey);
