import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { flow } from 'lodash';

import TermComponent from './OnboardingStepTerm.component';
import OnboardingStepLead from './OnboardingStepLead.component';
import OnboardingStepDocuments from './OnboardingStepDocuments.component';
import OnboardingStepLiveness from './OnboardingStepLiveness.component';
import OnboardingStepAddress from './OnboardingStepAddress.component';
import OnboardingStepValidationPhone from './OnboardingStepValidationPhone.component';
import OnboardingStepValidationEmail from './OnboardingStepValidationEmail.component';
import OnboardingStepPassword from './OnboardingStepPassword.component';
import OnboardingStepFinish from './OnboardingStepFinish.component';

import onboarding from '@giro-onboarding/store/onboarding';
import configs from '@giro-onboarding/store/configs';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import statusOnboarding from '@giro/shared-constants/statusOnboarding.constant';

import STEPS, { VARIANTS } from '@giro-onboarding/constants/steps.constant';

const Step = {
  [STEPS.TERM]: TermComponent,
  [STEPS.LEAD]: OnboardingStepLead,
  [STEPS.DOCUMENTS]: OnboardingStepDocuments,
  [STEPS.LIVENESS]: OnboardingStepLiveness,
  [STEPS.ADDRESS]: OnboardingStepAddress,
  [STEPS.VALIDATION_PHONE]: OnboardingStepValidationPhone,
  [STEPS.VALIDATION_EMAIL]: OnboardingStepValidationEmail,
  [STEPS.PASSWORD]: OnboardingStepPassword,
  [STEPS.FINISH]: OnboardingStepFinish,
};

const OnboardingStepsComponent = () => {
  const { user } = useAuthHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateVariant: flow(onboarding.action.updateVariant, dispatch),
    updateStep: flow(onboarding.action.updateStep, dispatch),
    updatePayload: flow(onboarding.action.updatePayload, dispatch),
    selectMode: flow(configs.action.selectMode, dispatch),
  };

  const selectorRedux = {
    step: useSelector(onboarding.selector.selectStep),
    variant: useSelector(onboarding.selector.selectVariant),
    payload: useSelector(onboarding.selector.selectPayload),
    mode: useSelector(configs.selector.selectMode),
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectorRedux.step, selectorRedux.variant]);

  React.useEffect(() => {
    const email = user?.user?.email;
    const name = user?.user?.name;

    dispatchRedux.updatePayload({
      ...selectorRedux.payload,
      email,
      name,
    });
  }, [user?.user]);

  React.useEffect(() => {
    const origin = user?.user?.origin;

    const isSSO = ['Google'].includes(origin);

    if (isSSO && selectorRedux.mode !== 'sso') {
      dispatchRedux.selectMode('sso');
    }

    if (!isSSO && selectorRedux.mode !== 'default') {
      dispatchRedux.selectMode('default');
    }
  }, [user]);

  const Component = Step[selectorRedux.step];

  return <Component />;
};

export default OnboardingStepsComponent;
