import React from 'react';
import { Stack, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Formik } from 'formik';
import * as Yup from 'yup';

import useIsSSOModeHook from '@giro-onboarding/hooks/useIsSSO.hook';

import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldPassword from '@giro/shared-components/Fields/FieldPassword.component';

import onboarding from '@giro-onboarding/store/onboarding';

const OnboardingStepPasswordComponent = () => {
  const isSSO = useIsSSOModeHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    stepNext: flow(onboarding.action.stepNext, dispatch),
  };

  const selectorRedux = {
    payload: useSelector(onboarding.selector.selectPayload),
  };

  React.useEffect(() => {
    if (isSSO) {
      dispatchRedux.stepNext();
    }
  }, [isSSO]);

  const initialValues = {
    password: selectorRedux.payload.password,
    confirmPassword: selectorRedux.payload.confirmPassword,
  };

  const schema = Yup.object({
    password: Yup.string()
      .required(messages.REQUIRED)
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        messages.PASSWORD_SPECS
      ),
    confirmPassword: Yup.string()
      .required(messages.REQUIRED)
      .oneOf(
        [Yup.ref('password'), null],
        messages.PASSWORD_VERIFICATION_EQUALS
      ),
  });

  const handleSubmit = async (values) => {
    dispatchRedux.stepNext(values);

    return true;
  };

  const fields = (
    <Stack gap={3}>
      <FieldPassword name="password" label="Senha" />
      <FieldPassword name="confirmPassword" label="Confirmação de senha" />
    </Stack>
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ errors, submitForm }) => (
        <Stack gap={6}>
          {fields}
          <Stack gap={2}>
            <Button
              onClick={() => {
                dispatchRedux.stepNext();
              }}
            >
              Pular
            </Button>
            <Button
              onClick={submitForm}
              variant="contained"
              disabled={Object.keys(errors).length > 0}
            >
              Atualizar
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
};

export default OnboardingStepPasswordComponent;
