export const NAME = 'onboarding';

const CONTEXT = 'ONBOARDING';

const SERVICE_GET = `${CONTEXT}::SERVICE::GET`;
const SERVICE_GET_ZIPCODE = `${CONTEXT}::SERVICE::GET::ZIPCODE`;
const SERVICE_GET_CODE = `${CONTEXT}::SERVICE::GET::CODE`;
const SERVICE_POST = `${CONTEXT}::SERVICE::POST`;
const SERVICE_POST_DOCUMENTS = `${CONTEXT}::SERVICE::POST::DOCUMENTS`;
const SERVICE_POST_SELFIE = `${CONTEXT}::SERVICE::POST::SELFIE`;
const SERVICE_PATCH_CODE = `${CONTEXT}::SERVICE::PATCH::CODE`;

const FETCH_START = `${CONTEXT}::FETCH::START`;
const FETCH_ERROR = `${CONTEXT}::FETCH::ERROR`;
const FETCH_SUCCESS = `${CONTEXT}::FETCH::SUCCESS`;

const UPDATE_STEP = `${CONTEXT}::UPDATE::STEP`;
const UPDATE_VARIANT = `${CONTEXT}::UPDATE::VARIANT`;
const UPDATE_PAYLOAD = `${CONTEXT}::UPDATE::PAYLOAD`;
const UPDATE_USER = `${CONTEXT}::UPDATE::USER`;
const UPDATE_PAYLOAD_DOCUMENTS = `${UPDATE_PAYLOAD}::DOCUMENTS`;

const STEP_NEXT = `${CONTEXT}::STEP::NEXT`;

const HELLO_WORLD = `${CONTEXT}::HELLO_WORLD`;

const RESET_STATE = `${CONTEXT}::RESET::STATE`;
const RESET_VARIANT = `${CONTEXT}::RESET::VARIANT`;
const RESET_SERVICE = `${CONTEXT}::RESET::SERVICE`;

const UPDATE = {
  STEP: UPDATE_STEP,
  VARIANT: UPDATE_VARIANT,
  PAYLOAD: UPDATE_PAYLOAD,
  PAYLOAD_DOCUMENTS: UPDATE_PAYLOAD_DOCUMENTS,
  USER: UPDATE_USER,
};

const STEP = {
  NEXT: STEP_NEXT,
};

const RESET = {
  STATE: RESET_STATE,
  VARIANT: RESET_VARIANT,
  SERVICE: RESET_SERVICE,
};

const SERVICE = {
  GET: SERVICE_GET,
  GET_ZIPCODE: SERVICE_GET_ZIPCODE,
  GET_CODE: SERVICE_GET_CODE,
  POST: SERVICE_POST,
  POST_DOCUMENTS: SERVICE_POST_DOCUMENTS,
  POST_SELFIE: SERVICE_POST_SELFIE,
  PATCH_CODE: SERVICE_PATCH_CODE,
};

const FETCH = {
  START: FETCH_START,
  ERROR: FETCH_ERROR,
  SUCCESS: FETCH_SUCCESS,
};

export const ACTION_TYPES = {
  HELLO_WORLD,
  STEP,
  UPDATE,
  RESET,
  SERVICE,
  FETCH,
};
