import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import {
  Box,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';

import onboarding from '@giro-onboarding/store/onboarding';
import apps from '@giro-onboarding/store/apps';

import STEPS, { VARIANTS } from '@giro-onboarding/constants/steps.constant';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const TermComponent = () => {
  const dispatch = useDispatch();

  const auth = useAuthHook();

  const loadings = {
    loadingOnboarding: useSelector(onboarding.selector.selectLoading),
    loadingApps: useSelector(apps.selector.selectLoading),
  };

  React.useEffect(() => {
    const userChecked =
      auth.user?.user?.checked_email ||
      auth.user?.user?.checked_phone ||
      auth.user?.user?.checked_selfie ||
      auth.user?.user?.checked_document ||
      auth.user?.user?.checked_bgc;

    if (
      userChecked &&
      loadings.loadingApps === false &&
      loadings.loadingOnboarding === false
    ) {
      dispatchRedux.stepNext();
    }
  }, [loadings.loadingApps, loadings.loadingOnboarding]);

  const dispatchRedux = {
    stepNext: flow(onboarding.action.stepNext, dispatch),
    updateVariant: flow(onboarding.action.updateVariant, dispatch),
    updateStep: flow(onboarding.action.updateStep, dispatch),
  };

  const handleAgree = () => {
    dispatchRedux.stepNext();
  };

  const handleDisagree = () => {
    // dispatchRedux.updateVariant(VARIANTS.FINISH.TERM);
    // dispatchRedux.updateStep(STEPS.FINISH);

    (window as any).location.href = process.env.REACT_APP_APP_URL;
  };

  return (
    <Box display="flex" flexDirection="column" gap={6}>
      <Box
        height={400}
        overflow="scroll"
        display="flex"
        flexDirection="column"
        gap={1.5}
      >
        <Box
          position="sticky"
          top={0}
          bgcolor="white"
          boxShadow="0 0 10px 1px #0000000a"
          pb={1}
        >
          <Typography variant="caption" color="primary.main">
            Deslize até o final para ler e aceitar o termo
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Typography variant="body_sm" color="grey.500">
            Termos de Uso dos Serviços da Giro
            <br />
            Última atualização: 26/05/2022.
            <br />
            <br />
            <strong>Quem somos</strong>
            <br />
            <br />A Giro – Giro Pagamentos e Tecnologia Ltda. é uma sociedade
            limitada, inscrita no CNPJ/MF sob nº 23.041.219/0001-34, titular dos
            direitos de propriedade intelectual da plataforma
            https://www.giropagamentos.com.br.
            <br />
            <br />
            <strong>1. O propósito deste documento</strong>
            <br />
            <br />
            Pelos presentes “Termos e Condições de Uso” ficam estabelecidos os
            direitos e obrigações dos USUÁRIOS da plataforma
            https://www.giropagamentos.com.br.
            <br />
            <br />
            <strong>2. Definições</strong>
            <br />
            <br /> “Giro”: https://www.giropagamentos.com.br.
            <br />
            <br />
            “USUÁRIO”: aquele que utiliza a plataforma da Giro,
            independentemente de login ou cadastro.
            <br />
            <br />
            “Política de Privacidade”: documento pelo qual a Giro esclarece, em
            detalhes, o tratamento de Dados Pessoais do USUÁRIO, em atendimento
            à legislação vigente, sobretudo às disposições da Lei Geral de
            Proteção de Dados (Lei nº 13.709/2018);
            <br />
            <br />
            “LGPD”: significa “Lei Geral de Proteção de Dados”, trata-se da Lei
            nº 13.709/2018;
            <br />
            <br />
            “Dados Pessoais”: qualquer informação relacionada a pessoa natural
            que a identifique, ou que, usada em combinação com outras
            informações tratadas, identificam um indivíduo. Ainda, qualquer
            informação por meio da qual a identificação ou informação de contato
            de uma pessoa natural seja possível;
            <br />
            <br />
            “Titular”: pessoa natural a quem se referem os Dados Pessoais objeto
            do tratamento;
            <br />
            <br />
            “Tratamento de Dados Pessoais”: considera-se tratamento de dado
            pessoal a coleta, produção, recepção, classificação, utilização,
            acesso, reprodução, transmissão, distribuição, processamento,
            arquivamento, armazenamento, eliminação, avaliação ou controle da
            informação, modificação, comunicação, transferência, difusão ou
            extração de dados de pessoas físicas;
            <br />
            <br />
            <strong>3. Contratação, Mandato e Legislação Aplicável</strong>
            <br />
            <br />
            Ao utilizar a plataforma, o aplicativo ou os canais de atendimento,
            o USUÁRIO concorda com os presentes “Termos e Condições de Uso” e a
            “Política de Privacidade” da Giro.
            <br />
            <br />
            As relações jurídicas decorrentes do presente contrato são regidas
            pela legislação brasileira, especialmente pelo Marco Civil da
            Internet (Lei nº 12.965/2014) e seu Decreto Regulamentador (Decreto
            nº 8.771/2016), pela Lei Geral de Proteção de Dados (Lei nº
            13.709/2018), pelo Código de Defesa do Consumidor (Lei nº
            8.078/1990) e pelo Código Civil (Lei nº 10.406/2002), sem prejuízo
            das demais normas vigentes.
            <br />
            <br />
            Considerando que o USUÁRIO é o Titular dos Dados, inclusive daqueles
            contidos em bases de dados de acesso público, como, mas não somente
            a base de dados do Departamento de Trânsito Nacional (“DENATRAN”) e
            Departamentos Estaduais de Trânsito (“DETRAN”), o USUÁRIO outorga a
            Giro procuração em caráter irrevogável, irretratável e não
            exclusivo, por meio da qual constitui a Giro como seu bastante
            procurador para, em seu nome, acessar e coletar seus Dados Pessoais
            em bases de dados de acesso público, incluindo, mas não se limitando
            às bases de dados de órgãos da administração pública como o DETRAN.
            <br />
            <br />O USUÁRIO autoriza que a Giro atue como seu legítimo
            despachante perante referidos órgãos, de modo que a Giro possa ter
            acesso aos DADOS do USUÁRIO para fins exclusivos dos serviços
            prestados pela Giro, nos termos de nossa Política de Privacidade e
            dentro dos limites e parâmetros definidos pela LGPD.
            <br />
            <br />A procuração em causa própria, com autorização do Titular para
            coleta e tratamento dos Dados Pessoais pela Giro para os fins
            exclusivos da prestação dos serviços, é outorgada pelo USUÁRIO por
            sua livre manifestação de vontade, sendo certo que o USUÁRIO detém
            legitimidade e interesse para postular seus direitos em juízo, nos
            termos do art. 17 do Código de Processo Civil (“CPC”), o que se
            estende aos órgãos administrativos, e inclui o direito de postular e
            exercer todos os direitos do USUÁRIO relacionados aos Dados Pessoais
            de sua titularidade, conforme previstos pelo art. 18 da LGPD.
            <br />
            <br />
            Assim sendo, por meio dos presentes Termos, o USUÁRIO convenciona,
            por sua autonomia e liberalidade, nos termos do art. 190 do CPC, que
            a Giro terá legitimidade para, em nome do USUÁRIO, postular os
            direitos em relação aos Dados Pessoais contidos em bases de acesso
            público, incluindo, mas não somente os Dados Pessoais de propriedade
            do USUÁRIO, coletados, compartilhados e informados pelo DETRAN,
            sendo as bases de dados acessadas pela Giro para devida prestação
            dos serviços contratados pelo USUÁRIO, conforme as bases legais
            descritas na nossa Política de Privacidade.
            <br />
            <br />
            Os poderes outorgados pelo USUÁRIO a Giro se dão em caráter não
            exclusivo, estando livre o USUÁRIO para contratar de serviços
            equivalentes prestados por terceiros, inclusive despachantes que
            atuam junto aos órgãos administrativos de trânsito, e autorizar que
            terceiros tenham acesso aos Dados Pessoais de sua titularidade.
            <br />
            <br />
            <strong>
              4. Alteração dos “Termos e Condições de Uso” e da “Política de
              Privacidade”
            </strong>
            <br />
            <br />A Giro poderá modificar, a qualquer tempo, os presentes
            “Termos e Condições de Uso” e a sua “Política de Privacidade”, para
            refletir eventuais alterações necessárias, visando ao aprimoramento
            e à correspondência com os serviços prestados ou para atendimento de
            questões legais, administrativas ou ordens judiciais.
            <br />
            <br />
            As versões atualizadas serão publicadas no site e no app, indicados
            acima, que deverão ser consultados regularmente. Além disso, a Giro
            informará ao USUÁRIO acerca de alterações significativas por
            quaisquer das seguintes formas: avisos por e-mail de USUÁRIO
            cadastrado, mensagens na plataforma ou no app, banners no website ou
            aplicativo, ou outro meio considerado adequado.
            <br />
            <br />
            <strong>
              5. Alteração dos Serviços e Funcionalidades do Sistema
            </strong>
            <br />
            <br />A Giro poderá realizar modificações (updates) nos serviços
            próprios do sistema e nas funcionalidades existentes, a fim de
            promover a melhoria do sistema ou sua adequação a determinada
            imposição legal ou judicial.
            <br />
            <br />
            <strong>6. Política de Tratamento de Dados Pessoais</strong>
            <br />
            <br />
            Ao utilizar a plataforma, o app e/ou os canais de atendimento da
            Giro, o USUÁRIO declara estar ciente da “Política de Privacidade”
            por ele estabelecida e que aceita e concorda com as previsões nela
            descritas.
            <br />
            <br />É de exclusiva responsabilidade do USUÁRIO a inserção de Dados
            Pessoais corretos e atualizados, autorizando a Giro a tratá-los para
            fins que atendam às bases legais previstas pelos arts. 7º e 11 da
            LGPD, em especial para execução do contrato, cumprimento de
            obrigação legal, exercício regular do direito ou quando justificado
            pelo legítimo interesse do controlador ou de terceiro, admitindo-se,
            quando legalmente justificado, o compartilhamento.
            <br />
            <br />
            <strong>7. Cadastro e utilização</strong>
            <br />
            <br />A Giro possui uma plataforma na qual há possibilidade de
            cadastro. Todas as informações coletadas buscam simplificar a vida
            do motorista, possibilitando a prestação dos serviços ofertados. O
            USUÁRIO fica ciente de que os dados fornecidos serão utilizados para
            acessar bases de dados de órgãos públicos, tais como o Detran, o
            Senatran, a Secretaria da Fazenda e outros que eventualmente sejam
            necessários para os fins estabelecidos nestes “Termos e Condições de
            Uso” e na “Política de Privacidade”.
            <br />
            <br />
            Todas as informações são inseridas pelo USUÁRIO, assim, este garante
            e responde, em qualquer caso, pela veracidade e atualização dos
            dados informados.
            <br />
            <br />O USUÁRIO é o único e exclusivo responsável por todas as
            atividades que ocorram em sua conta e se compromete a informar a
            Giro imediatamente acerca de qualquer uso ou suspeita de uso não
            autorizado de sua conta ou qualquer outra violação de segurança. a
            Giro não será responsável por quaisquer danos resultantes de acessos
            não autorizados ou uso da conta do USUÁRIO, pois a senha é de seu
            uso pessoal e conhecimento exclusivo.
            <br />
            <br />
            Apenas pessoas civilmente capazes podem efetuar cadastro e utilizar
            os serviços da Giro.
            <br />
            <br />
            Os serviços oferecidos pela Giro podem integrar-se com aplicações de
            terceiros. Nesse sentido, sempre serão buscados parceiros
            confiáveis. Nada obstante, lembre-se: a prestação de serviços feita
            pelos parceiros será regida de acordo com o conteúdo firmado nos
            Termos e Condições e na Política de Privacidade de cada um deles,
            não tendo a Giro ingerência sobre esses documentos. Por isso, o
            USUÁRIO deve ler tais disposições sempre que sair da plataforma da
            Giro para garantir que está de acordo com elas.
            <br />
            <br />
            Ademais, a Giro não possui ingerência em relação aos dados
            disponibilizados nas bases de dados de órgãos públicos, necessários
            para os fins estabelecidos nestes “Termos e Condições de Uso” e na
            “Política de Privacidade”, não sendo responsável de forma alguma,
            por qualquer desatualização, erro ou inconsistência de informações,
            gerados a partir destes dados.
            <br />
            <br />
            No menu “FAQ”, você encontra respostas a perguntas frequentes. Se a
            resposta que você procura não for encontrada nesta área, é possível
            inserir nome, e-mail e dúvida, sendo certo que os dados são os
            mínimos para retorno do seu contato.
            <br />
            <br />
            <strong>8. Pedidos e pagamentos</strong>
            <br />
            <br />A Giro oferece serviços com objetivo de simplificar a vida do
            USUÁRIO. Para utilizá-los, o USUÁRIO deve realizar o pedido pela
            plataforma, bem como o pagamento do valor ajustado para cada tipo de
            serviço contratado, eventuais despesas e funcionalidades adicionais.
            A ausência de pagamento pode implicar na suspensão dos serviços
            contratados, sem necessidade de aviso prévio, com restabelecimento
            apenas quando comprovada a quitação.
            <br />
            <br />
            Para facilitar a compreensão de cada serviço prestado,
            disponibilizamos informações em nossas FAQ (Perguntas Frequentes).
            <br />
            <br />
            Todos os serviços incluem os respectivos tributos, podendo a Giro
            discriminá-los ou não. Entretanto, havendo criação ou majoração de
            tributo posterior à contratação, a Giro se reserva o direito de
            atualizar os valores para compensar a diferença.
            <br />
            <br />O pagamento poderá ocorrer por meio de cartão de crédito ou
            débito, bem como transferência bancária via PIX, DOC, TED ou
            similares.
            <br />
            <br />A Giro poderá limitar os meios de pagamento disponíveis ao
            USUÁRIO no momento do pagamento, considerando os prazos para a
            liquidação estabelecidos pelos órgãos públicos e/ou pela legislação
            vigente, para cada um dos serviços prestados e/ou documentos
            expedidos.
            <br />
            <br />
            Na Giro, a segurança do titular é a prioridade e um compromisso.
            Assim, o seu pagamento está protegido pelas melhores empresas do
            mercado: Clearsale, GetNet e PagSeguro.
            <br />
            <br />A Giro não trata dados de pagamento. Todas as transações são
            feitas em plataformas de terceiros, sendo estas empresas as
            responsáveis pelo tratamento desses dados. Por isso, lembre-se de
            verificar se está de acordo com os Termos e Condições e Política de
            Privacidade da empresa responsável pela intermediação do pagamento
            ao realizar sua compra, pois a Giro não se responsabiliza por esse
            conteúdo.
            <br />
            <br />É importante verificar com a instituição financeira se ocorreu
            a aprovação ou não da compra, pois a Giro não possui ingerência
            sobre os critérios utilizados, sendo apenas informado do resultado.
            <br />
            <br />A Giro não aceitará, em nenhuma hipótese, que o pagamento se
            dê por qualquer meio operado por entidade localizada fora do
            território nacional.
            <br />
            <br />É assegurado a Giro efetuar procedimentos de verificação da
            identidade do USUÁRIO que, em caso de divergência ou inconsistência,
            pode resultar na negativa dos pedidos.
            <br />
            <br />
            Após o pedido, a Giro informará ao USUÁRIO um prazo estimado para a
            entrega do serviço, calculado de acordo com o meio de pagamento
            escolhido e com os prazos oficiais dos órgãos públicos. Esse prazo
            estimado pela Giro poderá sofrer alterações em razão de atrasos ou
            instabilidade(s) nos sistemas geridos por órgãos públicos. a Giro
            manterá o USUÁRIO informado sobre qualquer alteração nestes prazos.
            <br />
            <br />
            <strong>9. Política de Cancelamento</strong>
            <br />A Política de Cancelamento se aplica a todos aqueles que
            utilizam os serviços ofertados pela Giro através de sua plataforma
            e/ou canais de atendimento (incluindo, mas não se limitando a
            e-mail, whatsApp e chatbot) , o que é realizado em estrita
            observância destes Termos de Uso dos Serviços da Giro.
            <br />
            <br />
            9.1 Cancelamento de serviços
            <br />
            <br />
            Dentro da nossa plataforma e/ou canais de atendimento, o USUÁRIO
            conta com uma série de serviços focados em simplificar o seu dia a
            dia e melhorar sua experiência. Os USUÁRIOS podem usufruir do
            suporte da Giro para (i) pagamento de multas; (ii) pagamento de
            IPVA; (iii) pagamento de licenciamento; (iv) pagamento de contas e
            boletos, dentre outros serviços, sempre de forma simples, segura,
            rápida, transparente e sem burocracia.
            <br />
            <br />
            Contudo, o USUÁRIO entende que, por mais eficiente que seja a
            prestação de nossos serviços, em determinados momentos, estes
            poderão sofrer algum tipo de entrave procedimental e, nesta linha,
            de modo a resguardar a transparência e segurança, empenhamos os
            nossos melhores esforços, para englobar nessa política todas as
            situações que podem ser vivenciadas por nossos USUÁRIOS. Por isso,
            caso você pretenda cancelar algum dos serviços contratados, é
            necessário observar as seguintes hipóteses:
            <br />
            <br />
            9.1.1 Cancelamento de serviços por parte do USUÁRIO
            <br />
            <br />
            Em razão da nossa intenção de simplificar a vida do USUÁRIO, a Giro
            atenderá aos pedidos de cancelamento formulados pelos USUÁRIOS com a
            maior brevidade possível. Entretanto, existem situações em que,
            apesar do pedido de cancelamento, eventuais pagamentos poderão já
            ter sido processados pela Giro junto aos órgãos competentes, motivo
            pelo qual, nesses casos, não será possível o estorno integral dos
            valores pagos pelo USUÁRIO
            <br />
            <br />
            Para solicitar o cancelamento, o USUÁRIO deverá entrar em contato
            com a Giro por meio de um de nossos canais de atendimento.
            <br />
            <br />
            Ao solicitar o cancelamento, é necessário se identificar e indicar
            os dados do seu pedido e a razão pela qual está sendo solicitado o
            cancelamento. É importante que o USUÁRIO detalhe o que motivou a sua
            solicitação de cancelamento, para que a Giro, possa lhe conceder o
            melhor atendimento possível e, a partir do seu feedback, evoluir e
            melhorar os nossos serviços.
            <br />
            <br />
            Após a análise do pedido de cancelamento pelo setor responsável da
            Giro, a solicitação do USUÁRIO será devidamente respondida de forma
            fundamentada e, se aprovada, deve-se atentar que o prazo de estorno
            dos valores varia de acordo com o tipo de transação realizada,
            conforme tabela abaixo:
            <br />
            <br />
            <Box>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body_sm" color="grey.500">
                          Tipo de transação
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body_sm" color="grey.500">
                          Prazo
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body_sm" color="grey.500">
                          Boleto, PIX, DOC, TED e similares
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body_sm" color="grey.500">
                          deverão ocorrer, em regra, no prazo de até 03 (três)
                          dias úteis;
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body_sm" color="grey.500">
                          Cartões de crédito ou débito
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body_sm" color="grey.500">
                          deverão ocorrer, em regra, em até 02 (duas) faturas
                          após o pedido de cancelamento.
                        </Typography>
                        <br />
                        <br />
                        <Typography variant="body_sm" color="grey.500">
                          Importante observar, também, as regras de estorno de
                          valores da sua instituição bancária, o que poderá
                          interferir no prazo de estorno, independentemente da
                          atuação da Giro
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <br />
            <br />
            A Giro ressalta que, em certos casos, o estorno poderá ultrapassar
            as datas previstas acima, de modo que tudo será documentado e
            explicado ao USUÁRIO, garantindo máxima transparência e segurança na
            operação.
            <br />
            <br />
            Em alguns estados (São Paulo, por exemplo), a Giro possui acesso
            imediato aos órgãos responsáveis pelo recebimento das transações
            oriundas de nossos serviços, de modo que o pagamento ocorre
            automaticamente, em questão de minutos após a contratação de nossos
            serviços pelos USUÁRIOS. Entendemos que, por mais facilitadora e
            cômoda que seja tal interação, no cenário de cancelamento do
            serviço, o USUÁRIO conferirá, através da documentação comprobatória,
            que o pagamento foi realizado antes do efetivo cancelamento.
            <br />
            <br />
            9.1.2 Cancelamento de serviços por parte da Giro
            <br />
            <br />
            Nos casos em que a Giro não consegue realizar o devido pagamento do
            serviço ao órgão responsável, por alguma inconsistência de
            informação e/ou pendência que só pode ser quitada presencialmente
            pelo próprio USUÁRIO, a Giro poderá cancelar os serviços e, nesse
            caso, fará o estorno do valor integral pago pelo USUÁRIO dentro da
            plataforma por aquele determinado serviço.
            <br />
            <br />É de responsabilidade exclusiva dos USUÁRIOS a veracidade das
            informações prestadas aa Giro, de forma que, nos casos de
            inconsistências oriundas de informações relatadas pelos usuários
            (ex.: placa incorreta, dentre outros), os valores já pagos aos
            órgãos responsáveis não serão estornados ao USUÁRIO pela Giro e
            eventuais tarifas poderão ser cobradas a parte para a emissão de
            novo serviço.
            <br />
            <br />
            Importante destacar que sempre que a Giro optar pela realização do
            cancelamento, nos casos em que não conseguir realizar o devido
            pagamento do serviço ao órgão responsável, por alguma inconsistência
            de informação e/ou pendência que só pode ser quitada presencialmente
            pelo próprio USUÁRIO a Giro entrará em contato com o USUÁRIO,
            através do telefone e/ou e-mail informado no momento do cadastro,
            para cientificá-lo do cancelamento da contratação e do estorno da
            transação.
            <br />
            <br />O USUÁRIO, verificando o cancelamento, também poderá entrar em
            contato com a Giro, através de nossos canais de atendimento
            indicados abaixo, a fim de obter maiores informações sobre o motivo
            do cancelamento e sobre o estorno dos valores despendidos.
            <br />
            <br />
            Para os casos de cancelamento por parte da Giro de pagamentos
            realizados via boleto e similares, é imprescindível que o USUÁRIO
            atenda o contato com a Giro, a fim de indicar os dados bancários
            para os quais os valores deverão ser reembolsados. Caso o USUÁRIO
            não retorne o contato da Giro em até 3 (três) meses após o primeiro
            contato, os valores não serão estornados.
            <br />
            <br />
            9.2 Onde realizar o cancelamento
            <br />
            <br />
            Para o pronto cancelamento da transação, os USUÁRIOS deverão entrar
            em contato com a Giro por meio do canal de atendimento
            disponibilizado em nossa plataforma
            <br />
            <br />
            9.3 Modificação da Política de Cancelamento
            <br />
            <br />A Giro poderá modificar, alterar ou substituir esta Política
            de Cancelamento a qualquer tempo.
            <br />
            <br />
            <strong>10. Depoimentos e Avaliações dos USUÁRIOS</strong>
            <br />
            <br />
            Sabemos a importância das opiniões dos USUÁRIOS a respeito dos
            serviços prestados pela Giro. Visando garantir a transparência,
            compartilhamos dentro da plataforma algumas avaliações sobre os
            serviços ofertados pela Giro e por nossos parceiros, utilizando
            nome, imagem, depoimentos e comentários dos USUÁRIOS.
            <br />
            <br />
            Para que tal compartilhamento seja possível, o USUÁRIO expressamente
            concede uma autorização de uso de imagem e dados biográficos, bem
            como uma licença de direitos autorais relacionada às avaliações em
            questão, para que sejam utilizados em conformidade aos fins ora
            delimitados, a título gratuito e por prazo indeterminado.
            <br />
            <br />
            <strong>11. Responsabilidades do USUÁRIO</strong>
            <br />
            <br />
            Os USUÁRIOS devem observar a legislação vigente e as boas práticas
            comerciais, guiando sua conduta por preceitos éticos estabelecidos.
            Neste sentido, é proibido utilizar os serviços da Giro para
            finalidades ilícitas.
            <br />
            <br />
            Todas as informações inseridas pelo USUÁRIO são de sua exclusiva
            responsabilidade, não podendo recair sobre a Giro qualquer
            consequência advinda de erro, desatualização ou inconsistência.
            <br />
            <br />
            Por fim, o USUÁRIO é o único e exclusivo responsável:
            <br />
            <br />
            (i) por todos e quaisquer tributos, taxas, impostos, contas ou
            boletos decorrentes dos serviços, nos termos aventados;
            <br />
            <br />
            (ii) por fornecer dados corretos, atualizados e exatos em todas as
            áreas da plataforma;
            <br />
            <br />
            (iii) por quaisquer danos, diretos ou indiretos, causados por sua
            ação ou omissão;
            <br />
            <br />
            Em caso de identificação de fraude, dolo ou má-fé por parte do
            USUÁRIO, este deverá tornar a Giro indene, sem prejuízo da
            possibilidade de seu bloqueio imediato e cancelamento do cadastro.
            <br />
            <br />
            <strong>12. Segurança da Informação</strong>
            <br />
            <br />A Giro se compromete a manter a confidencialidade, integridade
            e segurança de quaisquer informações disponibilizadas pelo USUÁRIO
            ou a ele relacionadas. Sem prejuízo de outras medidas, todas as
            informações são armazenadas na Microsoft Azure, ambiente
            internacionalmente reconhecido pelo alto nível de segurança. Além
            disso, o site é seguro com Certificado SSL.
            <br />
            <br />O USUÁRIO reconhece que nenhum sistema, servidor ou software
            está absolutamente imune a ataques e/ou invasões de hackers e outros
            agentes maliciosos, não sendo a Giro responsável por qualquer
            exclusão, obtenção, utilização ou divulgação não autorizada de
            informações resultantes de ataques que a Giro não poderia
            razoavelmente evitar por meio das referidas medidas de segurança.
            <br />
            <br />
            <strong>13. Falhas e/ou instabilidade</strong>
            <br />
            <br />A Giro não garante o acesso e uso continuado ou ininterrupto à
            plataforma Giro, que pode, eventualmente, não estar disponível
            devido a dificuldades técnicas ou falhas de conexão à rede e/ou aos
            sistemas, subsistemas e servidores geridos pelo poder público.
            <br />
            <br />
            Sendo assim, o USUÁRIO reconhece que nenhum sistema, servidor ou
            software está absolutamente imune a instabilidades, não sendo a Giro
            responsável por qualquer dano, prejuízo ou perda sofridos pelo
            USUÁRIO em razão de tais falhas e/ou instabilidades.
            <br />
            <br />O USUÁRIO concorda em não exigir ressarcimento algum em
            virtude de prejuízos resultantes das dificuldades mencionadas acima,
            assim como por qualquer outro tipo de dano ocasionado por falhas e
            indisponibilidades alheias ao controle e ingerência da Giro.
            <br />
            <br />
            <strong>14. Propriedade Intelectual</strong>
            <br />
            <br />A Giro é a única titular dos direitos relacionados à
            Propriedade Intelectual que venham a ser disponibilizados na
            plataforma.
            <br />
            <br />
            Estes “Termos e Condições de Uso” não transferem ou cedem ao USUÁRIO
            os direitos relativos à Propriedade Intelectual ou outros direitos
            exclusivos, incluindo marcas, programas de computador, patentes,
            desenhos, direitos autorais ou direitos sobre informações
            confidenciais ou segredos de negócio, sobre ou relacionados à
            plataforma ou qualquer outra parte dela.
            <br />
            <br />
            Tal relação contratual é norteada pela Lei de Direitos Autorais (Lei
            nº 9.610/1998), Lei de Propriedade Industrial (Lei nº 9.279/1996),
            Lei do Software (Lei nº 9.609/1998) e no que essas leis forem
            omissas, pelo Código Civil (Lei nº 10.406/2002) e Constituição
            Federal (1988). Deverão ainda ser respeitadas todas as normativas do
            Instituto Nacional da Propriedade Industrial (INPI) e da Biblioteca
            Nacional, assim como todos tratados internacionais e regulamentos
            aplicáveis que regulam sobre direitos da propriedade intelectual.
            <br />
            <br />A Giro reserva para si todos os direitos legais, títulos e
            interesses, tais como: direitos Autorais, patentes, segredos de
            negócio e quaisquer outros direitos de Propriedade Intelectual,
            incluindo marcas, sinais distintivos, nomes de domínio na Internet e
            nomes comerciais que são exibidos no referido software, bem como
            seus códigos, interface gráfica, componentes e design.
            <br />
            <br />O USUÁRIO não pode reproduzir, publicar, apresentar, alugar,
            oferecer ou expor qualquer cópia de qualquer conteúdo pertencente aa
            Giro, incluindo textos, logotipos, marcas, músicas, sons,
            fotografias, vídeos, sem a autorização da Giro ou, no caso de
            conteúdo de autoria de terceiros, sem a autorização do autor ou
            autora.
            <br />
            <br />A Giro reserva a si todos os direitos, salvo se expressamente
            licenciados ou concedidos às empresas parceiras em relação à
            plataforma ou ao app, sobretudo em relação a seus elementos e/ou
            ferramentas. Tais elementos e/ou ferramentas não podem ser usados,
            copiados, reproduzidos, distribuídos, transmitidos, difundidos,
            imitados, exibidos, vendidos, licenciados ou, de outro modo,
            explorados para quaisquer fins, sem o consentimento prévio e por
            escrito da Giro.
            <br />
            <br />É proibido desmontar, descompilar, tentar traduzir ou praticar
            quaisquer atos que caracterizem prática de spam, flood, envio de
            vírus, SQL Inject, engenharia reversa ou qualquer outra medida que
            vise derrubar os sistemas para acessar código fonte ou outras
            informações. Também são vedadas práticas que coloquem em risco a
            segurança da plataforma ou app ou que de qualquer forma possam
            danificar, inutilizar, sobrecarregar ou deteriorar dispositivos
            informáticos próprios ou de terceiros e, ainda, material protegido
            por leis de propriedade intelectual ou industrial pertencentes a
            terceiros, sem autorização de seus titulares ou que viole segredos
            empresariais.
            <br />
            <br />É vedada a utilização e reprodução, no todo ou em parte, por
            qualquer forma ou meio das marcas, nomes, logotipos ou outros sinais
            distintivos da Giro pelo USUÁRIO, sem autorização expressa de seu
            titular.
            <br />
            <br />
            Em caso de violação dos direitos de Propriedade Intelectual
            disponibilizados na plataforma ou app da Giro, além da rescisão de
            qualquer contrato celebrado, quando apropriado, o USUÁRIO fica
            solidariamente responsável pelo pagamento de multas e de indenização
            por danos suplementares. O infrator ficará sujeito ainda às sanções
            penais previstas nas Leis de Direito Autoral e de Propriedade
            Industrial e outras porventura aplicáveis.
            <br />
            <br />
            <strong>15. Disposições Finais</strong>
            <br />
            <br />O USUÁRIO concorda em não burlar, desativar ou, de qualquer
            forma, interferir em ferramentas e/ou recursos relacionados à
            segurança da plataforma, sob pena de responder civil e criminalmente
            por tais atos.
            <br />
            <br />
            Na hipótese de qualquer item do presente contrato vir a ser
            declarado nulo ou não aplicável, tal nulidade ou inexequibilidade
            não afetará os demais itens, termos ou disposições contidas, os
            quais permanecerão em pleno vigor e efeito.
            <br />
            <br />O não exercício pela Giro de qualquer dos direitos previstos
            nestes “Termos e Condições de Uso” ou mesmo a sua tolerância no
            atraso ou falta de cumprimento total ou parcial de quaisquer
            obrigações, não constituirá novação ou renúncia a tais direitos e
            obrigações, nem poderá ser considerado precedente ou autorização
            tácita, devendo ser interpretado como mera liberalidade da Giro.
            <br />
            <br />A Giro poderá ceder os direitos decorrentes do presente
            instrumento, no caso de transferência de tecnologia ou da
            propriedade intelectual para terceiros, cuidando para que o
            adquirente respeite todos os direitos e obrigações previstos, sem
            que haja qualquer obrigação de anuência prévia pelo USUÁRIO.
            <br />
            <br />
            <strong>16. Informações de Contato</strong>
            <br />
            <br />
            Caso o USUÁRIO tenha dúvidas sobre este documento e as práticas nele
            descritas, poderá entrar em contato com a Giro por meio de um dos
            seguintes canais de comunicação disponibilizados em sua plataforma.
            <br />
            <br />
            <strong>17. Foro de Eleição</strong>
            <br />
            <br />
            Qualquer controvérsia decorrente da interpretação ou aplicação do
            presente Contrato será resolvida de acordo com o princípio da
            boa-fé, elegendo as partes a Comarca da Capital do Estado de São
            Paulo como competente para processar e julgar quaisquer disputas
            oriundas desta relação.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap={2} p={4}>
          <Button variant="outlined" onClick={handleDisagree} fullWidth>
            Não aceito
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgree}
            fullWidth
          >
            Aceito
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TermComponent;
