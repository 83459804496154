/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@mui/material';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

export default class ReactCodeInput extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['text', 'number']),
    onChange: PropTypes.func,
    onComplete: PropTypes.func,
    fields: PropTypes.number,
    loading: PropTypes.bool,
    title: PropTypes.string,
    fieldWidth: PropTypes.number,
    id: PropTypes.string,
    fieldHeight: PropTypes.number,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    type: 'number',
    fields: 6,
    fieldWidth: 58,
    fieldHeight: 54,
    autoFocus: true,
    disabled: false,
    required: false,
    placeholder: [],
  };

  constructor(props) {
    super(props);
    const { fields, values } = props;
    let vals;
    let autoFocusIndex = 0;
    if (values && values.length) {
      vals = [];
      for (let i = 0; i < fields; i++) {
        vals.push(values[i] || '');
      }
      autoFocusIndex = values.length >= fields ? 0 : values.length;
    } else {
      vals = Array(fields).fill('');
    }
    this.state = { values: vals, autoFocusIndex };

    this.iRefs = [];
    for (let i = 0; i < fields; i++) {
      this.iRefs.push(React.createRef());
    }
    this.id = +new Date();
  }

  /**
   * Clear all field value & focus first field
   */
  __clearvalues__ = () => {
    const { fields } = this.props;
    this.setState({ values: Array(fields).fill('') });
    this.iRefs[0].current.focus();
  };

  triggerChange = (values = this.state.values) => {
    const { onChange, onComplete, fields } = this.props;
    const val = values.join('');
    onChange && onChange(val);
    if (onComplete && val.length >= fields) {
      onComplete(val);
    }
  };

  onChange = (e, index) => {
    if (this.props.type === 'number') {
      e.target.value = e.target.value.replace(/[^\d]/gi, '');
    }
    // this.handleKeys[index] = false;
    if (
      e.target.value === '' ||
      (this.props.type === 'number' && !e.target.validity.valid)
    ) {
      return;
    }
    const { fields } = this.props;
    let next;
    const value = e.target.value;
    let { values } = this.state;
    values = Object.assign([], values);
    if (value.length > 1) {
      let nextIndex = value.length + index - 1;
      if (nextIndex >= fields) {
        nextIndex = fields - 1;
      }
      next = this.iRefs[nextIndex];
      const split = value.split('');
      split.forEach((item, i) => {
        const cursor = index + i;
        if (cursor < fields) {
          values[cursor] = item;
        }
      });
      this.setState({ values });
    } else {
      next = this.iRefs[index + 1];
      values[index] = value;
      this.setState({ values });
    }

    if (next) {
      const input = next.current.querySelector('input');
      input.focus();
      input.select();
    }

    this.triggerChange(values);
  };

  onKeyDown = (e, index) => {
    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prev = this.iRefs[prevIndex];
    const next = this.iRefs[nextIndex];

    const inputPrev = prev?.current?.querySelector('input');
    const inputNext = next?.current?.querySelector('input');

    switch (e.keyCode) {
      case KEY_CODE.backspace:
        e.preventDefault();
        const vals = [...this.state.values];

        if (this.state.values[index]) {
          vals[index] = '';
          this.setState({ values: vals });
          this.triggerChange(vals);
        } else if (prev) {
          vals[prevIndex] = '';
          inputPrev.focus();
          this.setState({ values: vals });
          this.triggerChange(vals);
        }
        break;
      case KEY_CODE.left:
        e.preventDefault();
        if (prev) {
          inputPrev.focus();
        }
        break;
      case KEY_CODE.right:
        e.preventDefault();
        if (next) {
          inputNext.focus();
        }
        break;
      case KEY_CODE.up:
      case KEY_CODE.down:
        e.preventDefault();
        break;
      default:
        // this.handleKeys[index] = true;
        break;
    }
  };

  onFocus = (e) => {
    e.target.select(e);
  };

  render() {
    const { values, autoFocusIndex } = this.state;
    const { autoFocus, type } = this.props;

    return (
      <Box display="flex" gap={1}>
        {values.map((value, index) => (
          <TextField
            autoComplete={index === 0 ? 'one-time-code' : 'off'}
            type={type === 'number' ? 'tel' : type}
            pattern={type === 'number' ? '[0-9]*' : null}
            autoFocus={autoFocus && index === autoFocusIndex}
            key={`${this.id}-${index}`}
            value={value}
            id={this.props.id ? `${this.props.id}-${index}` : null}
            ref={this.iRefs[index]}
            onChange={(ev) => this.onChange(ev, index)}
            onKeyDown={(ev) => this.onKeyDown(ev, index)}
            onFocus={this.onFocus}
            disabled={this.props.disabled}
            required={this.props.required}
            placeholder={this.props.placeholder[index]}
            sx={{
              input: {
                textAlign: 'center',
              },
            }}
          />
        ))}
      </Box>
    );
  }
}
