import { ACTION_TYPES } from './onboarding.constant';

export const helloWorld = (payload) => ({
  type: ACTION_TYPES.HELLO_WORLD,
  payload,
});

export const stepNext = (payload = {}) => ({
  type: ACTION_TYPES.STEP.NEXT,
  payload,
});

export const updateStep = (step) => ({
  type: ACTION_TYPES.UPDATE.STEP,
  step,
});

export const updateVariant = (variant) => ({
  type: ACTION_TYPES.UPDATE.VARIANT,
  variant,
});

export const updatePayload = (payload) => ({
  type: ACTION_TYPES.UPDATE.PAYLOAD,
  payload,
});

export const updateUser = (payload) => ({
  type: ACTION_TYPES.UPDATE.USER,
  payload,
});

export const updatePayloadDocuments = (payload) => ({
  type: ACTION_TYPES.UPDATE.PAYLOAD_DOCUMENTS,
  payload,
});

export const resetState = () => ({
  type: ACTION_TYPES.RESET.STATE,
});

export const resetVariant = () => ({
  type: ACTION_TYPES.RESET.VARIANT,
});

export const serviceGet = () => ({
  type: ACTION_TYPES.SERVICE.GET,
});

export const serviceGetCode = (payload: 'email' | 'phone') => ({
  type: ACTION_TYPES.SERVICE.GET_CODE,
  payload,
});

export const servicePost = () => ({
  type: ACTION_TYPES.SERVICE.POST,
});

export const servicePostDocuments = () => ({
  type: ACTION_TYPES.SERVICE.POST_DOCUMENTS,
});

export const servicePostSelfie = (payload) => ({
  type: ACTION_TYPES.SERVICE.POST_SELFIE,
  payload,
});

export const servicePatchCode = (payload: 'email' | 'phone', code) => ({
  type: ACTION_TYPES.SERVICE.PATCH_CODE,
  payload,
  code,
});

export const serviceGetZipcode = (zipcode) => ({
  type: ACTION_TYPES.SERVICE.GET_ZIPCODE,
  payload: zipcode,
});

export const resetService = () => ({
  type: ACTION_TYPES.RESET.SERVICE,
});

export const fetchStart = () => ({
  type: ACTION_TYPES.FETCH.START,
});

export const fetchError = (payload) => ({
  type: ACTION_TYPES.FETCH.ERROR,
  payload,
});

export const fetchSuccess = (payload) => ({
  type: ACTION_TYPES.FETCH.SUCCESS,
  payload,
});
