import { all } from 'redux-saga/effects';

import dialogSendReceipt from './dialogChangePhone';
import dialogChangeEmail from './dialogChangeEmail';

function* watch() {
  yield all([dialogChangeEmail.saga.watch(), dialogSendReceipt.saga.watch()]);
}

export default {
  watch,
};
