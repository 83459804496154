import reducer from './onboarding.reducer';
import saga from './onboarding.saga';
import * as action from './onboarding.action';
import * as selector from './onboarding.selector';
import * as constant from './onboarding.constant';
import subscriber from './onboarding.subscriber';
import initialState from './onboarding.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  name: constant.NAME,
};
