import { v4 } from 'uuid';

import STEPS, {
  STEPS_ORDER,
  VARIANTS,
} from '@giro-onboarding/constants/steps.constant';

const payloadMock = {
  uuid: v4(),

  //STEPS.LEAD
  name: 'Matheus Loureiro',
  document: '33608548025',
  email: 'loureiro@bh2.com.br',
  phone: '11913185177',

  //STEPS.DATA
  zipcode: '04727120',
  address: 'Rua Ministro Nelson Sampaio',
  number: '',
  complement: '',
  neighborhood: 'Vila Cruzeiro',
  city: 'São Paulo',
  state: 'SP',

  //STEPS.PASSWORD
  password: 'Matheus123!',
  confirmPassword: 'Matheus123!',

  //STEPS.DOCUMENTS
  documents: {},
};

const payload = {
  uuid: v4(),

  //STEPS.LEAD
  name: '',
  document: '',
  email: '',
  phone: '',

  //STEPS.DATA
  zipcode: '',
  address: '',
  number: '',
  complement: '',
  neighborhood: '',
  city: '',
  state: '',

  //STEPS.PASSWORD
  password: '',
  confirmPassword: '',

  //STEPS.DOCUMENTS
  documents: {},
};

const initialState = {
  step: STEPS_ORDER[0],
  variant: null,
  payload: payload,
  data: null,
  loading: null,
  user: null,
  error: [null, null],
};

export default initialState;
