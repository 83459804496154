import { combineReducers } from 'redux';

import auth from '@giro/shared-store/auth';
import toaster from '@giro/shared-store/toaster';

import onboardingReducer from '@giro-onboarding/store/onboarding';
import dialogsReducer from '@giro-onboarding/store/dialogs';
import configsReducer from '@giro-onboarding/store/configs';
import appsReducer from '@giro-onboarding/store/apps';

const appReducer = combineReducers({
  [auth.name]: auth.reducer,
  [toaster.name]: toaster.reducer,
  [appsReducer.name]: appsReducer.reducer,
  [configsReducer.name]: configsReducer.reducer,
  [dialogsReducer.name]: dialogsReducer.reducer,
  [onboardingReducer.name]: onboardingReducer.reducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
