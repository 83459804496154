import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import Routes from '@giro-onboarding/routes';

import themeConstants from '@giro/shared-constants/theme.constant';

import { useStore } from '@giro-onboarding/store';

import reportWebVitals from './reportWebVitals';

import './index.css';

const App = () => {
  const store = useStore();
  const theme = createTheme({ ...(themeConstants as any) });

  return (
    <Provider store={store}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <SnackbarProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </StylesProvider>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
