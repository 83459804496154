import React from 'react';
import { Stack, TextField as TextFieldMUI, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import validateCPF from '@giro/shared-utils/validateCPF.util';

import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldDocument from '@giro/shared-components/Fields/FieldDocument.component';
import FieldPhone from '@giro/shared-components/Fields/FieldPhone.component';

import onboarding from '@giro-onboarding/store/onboarding';
import configs from '@giro-onboarding/store/configs';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

import useIsSSOModeHook from '@giro-onboarding/hooks/useIsSSO.hook';

const OnboardingStepLeadComponent = () => {
  const isSSO = useIsSSOModeHook();

  const auth = useAuthHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    stepNext: flow(onboarding.action.stepNext, dispatch),
  };

  const selectorRedux = {
    payload: useSelector(onboarding.selector.selectPayload),
    configs: useSelector(configs.selector.selectState),
  };

  React.useEffect(() => {
    if (
      auth.user?.user?.checked_document &&
      auth.user?.user?.checked_selfie &&
      auth.user?.user?.checked_bgc
    ) {
      dispatchRedux.stepNext();
    }
  }, []);

  const initialValues = {
    name: selectorRedux.payload.name,
    document: selectorRedux.payload.document,
    email: selectorRedux.payload.email,
    phone: selectorRedux.payload.phone,
  };

  const schema = Yup.object({
    name: Yup.string().required(messages.REQUIRED),
    document: Yup.string()
      .required(messages.REQUIRED)
      .default('')
      .test('document_number', messages.DOCUMENT_INVALID, validateCPF),
    email: Yup.string().email(messages.EMAIL).required(messages.REQUIRED),
    phone: Yup.string().required(messages.REQUIRED).min(10, messages.MIN),
  });

  const handleSubmit = async (values) => {
    dispatchRedux.stepNext(values);

    return true;
  };

  const fields = (
    <Stack gap={3}>
      <Field name="name" component={TextField} label="Nome Completo" />
      <FieldDocument
        type="tel"
        name="document"
        label="CPF"
        format="###.###.###-##"
      />
      <Field
        disabled={isSSO}
        name="email"
        component={TextField}
        label="E-mail"
        type="email"
      />
      <FieldPhone type="tel" name="phone" />
    </Stack>
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ errors, submitForm }) => (
        <Stack gap={6}>
          {fields}
          <Stack>
            <Button
              onClick={submitForm}
              variant="contained"
              disabled={Object.keys(errors).length > 0}
            >
              Continuar
            </Button>
          </Stack>
        </Stack>
      )}
    </Formik>
  );
};

export default OnboardingStepLeadComponent;
