import React from 'react';

import OnboardingLayout from '../layouts/Onboarding.layout';
import OnboardingSteps from '../components/OnboardingSteps';

const OnboardingScreen = () => {
  return (
    <OnboardingLayout>
      <OnboardingSteps />
    </OnboardingLayout>
  );
};

export default OnboardingScreen;
