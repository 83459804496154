import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import {
  Box,
  Typography,
  Stack,
  Button,
  CircularProgress,
} from '@mui/material';

import { VARIANTS } from '@giro-onboarding/constants/steps.constant';

import onboarding from '@giro-onboarding/store/onboarding';
import configs from '@giro-onboarding/store/configs';

const OnboardingStepFinishComponent = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    updateVariant: flow(onboarding.action.updateVariant, dispatch),
    servicePost: flow(onboarding.action.servicePost, dispatch),
  };

  const selectorRedux = {
    variant: useSelector(onboarding.selector.selectVariant),
    loading: useSelector(onboarding.selector.selectLoading),
    configs: useSelector(configs.selector.selectState),
  };

  const handleServicePost = () => {
    dispatchRedux.servicePost();
  };

  React.useEffect(() => {
    const BLACKLIST = [
      VARIANTS.FINISH.TERM,
      VARIANTS.FINISH.SUCCESS,
      VARIANTS.FINISH.PROCESSING,
      VARIANTS.FINISH.REPROVED,
    ];

    if (!BLACKLIST.includes(selectorRedux.variant)) {
      dispatchRedux.updateVariant(VARIANTS.FINISH.DEFAULT);
    }
  }, []);

  React.useEffect(() => {
    const containInSteps = Array.from(selectorRedux.configs?.steps).includes(
      'require_bgc'
    );

    if (!containInSteps) {
      dispatchRedux.updateVariant(VARIANTS.FINISH.SUCCESS);
    }

    if (selectorRedux.variant === VARIANTS.FINISH.DEFAULT && containInSteps) {
      handleServicePost();
    }
  }, [selectorRedux.variant]);

  return (
    <>
      {selectorRedux.variant === VARIANTS.FINISH.DEFAULT && (
        <Box
          position="absolute"
          top="0px"
          left="0px"
          width="100%"
          height="100%"
          bgcolor="white"
          borderRadius={10}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}

      {selectorRedux.variant === VARIANTS.FINISH.SUCCESS && (
        <Stack gap={6}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography color="grey.500">
              Agora iremos analisar os arquivos enviados e em alguns minutos seu
              cadastro será ativado, avisaremos por e-mail.
            </Typography>
            <Typography color="grey.500">
              Caso ocorra algum problema, eviaremos um e-mail informando quais
              passos deverão ser refeitos.
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={() =>
              ((window as any).location.href = process.env.REACT_APP_APP_URL)
            }
          >
            Concluir
          </Button>
        </Stack>
      )}

      {selectorRedux.variant === VARIANTS.FINISH.PROCESSING && (
        <Stack gap={6}>
          <Typography>
            Seu cadastro está sendo processado, logo mais você receberá um email
            informando os próximos passos.
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              ((window as any).location.href = process.env.REACT_APP_APP_URL)
            }
          >
            Voltar
          </Button>
        </Stack>
      )}

      {selectorRedux.variant === VARIANTS.FINISH.REPROVED && (
        <Stack gap={6}>
          <Typography>
            Seu cadastro foi reprovado na nossa análise, entre em contato com a
            GiroPagamentos para mais informações
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              ((window as any).location.href = process.env.REACT_APP_APP_URL)
            }
          >
            Voltar
          </Button>
        </Stack>
      )}

      {selectorRedux.variant === VARIANTS.FINISH.ERROR && (
        <Stack gap={6}>
          <Typography>
            Algo aconteceu na hora de processar sua solicitação, tente novamente
            mais tarde.
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              ((window as any).location.href = process.env.REACT_APP_APP_URL)
            }
          >
            Voltar
          </Button>
        </Stack>
      )}
    </>
  );
};

export default OnboardingStepFinishComponent;
