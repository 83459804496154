import { useEffect } from 'react';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import ROUTES from '@giro-onboarding/constants/routes.constant';

export default function useProtectedByAuth() {
  const { signedIn } = useAuth();

  const authclientUrl = ROUTES.EXTERNAL.AUTH.LOGIN;

  useEffect(() => {
    if (!signedIn) {
      window.location.href = `${authclientUrl}?redirectUrl=${window.location.href}`;
    }
  }, [signedIn]);
}
