import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Box, Typography, Stack } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { Formik } from 'formik';

import { VARIANTS } from '@giro-onboarding/constants/steps.constant';

import UploadSelfie from '../UploadSelfie.component';

import onboarding from '@giro-onboarding/store/onboarding';
import configs from '@giro-onboarding/store/configs';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const OnboardingStepLivenessComponent = () => {
  const auth = useAuthHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateVariant: flow(onboarding.action.updateVariant, dispatch),
    stepNext: flow(onboarding.action.stepNext, dispatch),
    resetVariant: flow(onboarding.action.resetVariant, dispatch),
  };

  const selectorRedux = {
    variant: useSelector(onboarding.selector.selectVariant),
    loading: useSelector(onboarding.selector.selectLoading),
    payload: useSelector(onboarding.selector.selectPayload),
    configs: useSelector(configs.selector.selectState),
  };

  React.useEffect(() => {
    const containInSteps = Array.from(selectorRedux.configs?.steps).includes(
      'require_selfie'
    );

    const userChecked = auth.user?.user?.checked_selfie;

    if (!containInSteps || userChecked) {
      dispatchRedux.stepNext();
    }
  }, []);

  React.useEffect(() => {
    dispatchRedux.updateVariant(VARIANTS.LIVENESS.INITIAL);
  }, []);

  const handleNextStep = () => {
    dispatchRedux.stepNext();
  };

  const currentName = selectorRedux.payload?.name;

  const action = () => (
    <Stack>
      <Button
        loading={selectorRedux.loading}
        onClick={handleNextStep}
        variant="contained"
        // disabled={Object.keys(errors).length > 0}
      >
        Continuar
      </Button>
    </Stack>
  );

  const initial = (
    <Stack gap={6}>
      <Stack gap={0}>
        <Typography>
          <strong>{currentName}</strong>, aqui vão algumas dicas:
        </Typography>
        <ul>
          <li>
            <Typography>
              Retire os acessórios (óculos, boné, máscara...)
            </Typography>
          </li>
          <li>
            <Typography>Esteja em um local com boa iluminação</Typography>
          </li>
        </ul>
        <Typography>
          Você não precisará apertar nenhum botão, apenas enquadrar o seu rosto
          na câmera e seguir as orientações da tela.
        </Typography>
      </Stack>
      <Box display="grid" gridTemplateColumns="1fr" gap={4}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => dispatchRedux.updateVariant(VARIANTS.LIVENESS.TAKING)}
        >
          Começar
        </Button>
      </Box>
    </Stack>
  );

  const taking = (
    <Stack gap={6}>
      <Formik
        initialValues={{
          selfie: {
            files: [],
          },
        }}
        onSubmit={() => {}}
      >
        <UploadSelfie name="selfie" handleNext={() => handleNextStep()} />
      </Formik>
      {/* {action()} */}
    </Stack>
  );

  return (
    <>
      {selectorRedux.variant === VARIANTS.LIVENESS.INITIAL && initial}
      {selectorRedux.variant === VARIANTS.LIVENESS.TAKING && taking}
    </>
  );
};

export default OnboardingStepLivenessComponent;
