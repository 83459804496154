import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, GlobalStyles } from '@mui/material';
import {
  Container,
  Box,
  Typography,
  Switch,
  Divider,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { flow } from 'lodash';
import { LABELS } from '@giro-onboarding/constants/steps.constant';

import useAuth from '@giro/shared-hooks/useAuth.hook';

import Logo from '@giro/shared-components/Logo.component';

import onboarding from '@giro-onboarding/store/onboarding';
import apps from '@giro-onboarding/store/apps';

import statusOnboarding from '@giro/shared-constants/statusOnboarding.constant';

const OnboardingLayout = ({ children }) => {
  const { user } = useAuth();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const selectorRedux = {
    step: useSelector(onboarding.selector.selectStep),
    loadingOnboarding: useSelector(onboarding.selector.selectLoading),
    loadingApps: useSelector(apps.selector.selectLoading),
  };

  const titleLabel = LABELS[selectorRedux?.step] || '';

  return (
    <Fragment>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: isMobile ? 'white' : '#F4F0F7',
          },
        }}
      />
      <Container component="main" disableGutters>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={{
            xs: 'flex-start',
            md: 'center',
          }}
          flexDirection="column"
          gap={3}
          minHeight="100vh"
        >
          <Box
            mb={3}
            position="absolute"
            top={3}
            left={3}
            sx={{
              display: {
                xs: 'none',
                lg: 'block',
              },
              img: {
                maxWidth: 300,
              },
            }}
          >
            <Logo variant="new" />
          </Box>

          <Box
            width={{
              xs: '100%',
              md: 600,
            }}
            p={5}
            borderRadius={10}
            bgcolor="white"
            display="flex"
            flexDirection="column"
            gap={5}
            position="relative"
          >
            {titleLabel && (
              <Box display="flex" flexDirection="column" gap={3}>
                <Box
                  display={{
                    xs: 'none',
                    md: 'block',
                  }}
                >
                  <Logo variant="new-sym" width="40" />
                </Box>
                <Box
                  display={{
                    xs: 'flex',
                    md: 'none',
                  }}
                >
                  <Logo variant="new" width="200" />
                </Box>
                <Box>{titleLabel}</Box>
                <Box mx={-5}>
                  <Box
                    height="4px"
                    sx={{
                      background:
                        'linear-gradient(90deg, rgba(214,117,255,1) 0%, rgba(255,255,255,1) 100%)',
                    }}
                  />
                </Box>
              </Box>
            )}

            <Box>{children}</Box>

            {(selectorRedux.loadingApps || selectorRedux.loadingOnboarding) && (
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                borderRadius={10}
                zIndex={999}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>

          <Box
            maxWidth={600}
            mt={1}
            display="flex"
            flexDirection="column"
            mx={{ xs: 2, md: 0 }}
            mb={{
              xs: 2,
              md: 0,
            }}
          >
            <Typography align="center" variant="caption" color="grey.500">
              Giro Pagamentos e Tecnologia Ltda. <br /> CNPJ: 23.041.219/0001-34
            </Typography>
            <Typography align="center" variant="caption" color="grey.500">
              Copyright © 2022 Giro Pagamentos.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
};

export default OnboardingLayout;
