import React, { useState, useEffect, useRef, useCallback } from 'react';
import { flow } from 'lodash';
import { Grid, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useFormikContext } from 'formik';
import Webcam from 'react-webcam';
import CircularProgress from '@mui/material//CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  getFullFaceDescription,
  loadModels,
} from '@giro-onboarding/utils/face.util';

import { image64toCanvasRef } from '@giro-onboarding/utils/imageFns.util';

import onboarding from '@giro-onboarding/store/onboarding';

const ContainerWebcam = styled.div`
  .cameraContainer {
    width: 100%;
    height: 100%;
    position: relative;

    span {
      font-size: 24px;
    }
  }

  .cameraOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3rem;
    color: white;

    &.off {
      display: none;
    }

    span {
      width: 90%;
    }
  }

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    height: 100%;
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.on {
      display: flex;
    }
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scaleX(-1);
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3rem;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 10;
    transition: width 0.2s ease-in;
  }

  .hidden {
    display: none;
  }
`;

const LineStyled = styled.hr`
  height: 1px;
  border: 0;
  background: black;
  margin-left: -40px;
  margin-right: -40px;
`;

const UploadSelfie: React.FC<any> = ({ name }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const canvasRef = useRef(null);
  const cameraRef = useRef(null);

  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [frameIndex, setFrameIndex] = useState(0);
  const [withoutWebcam, setWithoutWebcam] = useState(false);
  const [sented, setSended] = useState(false);
  const [detectMessage, setDetectMessage] = useState(
    'Carregando sistema de detecção facial'
  );

  const { setFieldValue, values }: any = useFormikContext();

  const selectorRedux = {
    loading: useSelector(onboarding.selector.selectLoading),
  };

  const dispatchRedux = {
    servicePostSelfie: flow(onboarding.action.servicePostSelfie, dispatch),
  };

  const faceBounds = 264;
  const messagesArray = [
    {
      message: '',
      delay: 1,
    },
    {
      message: 'Sorria por 3 segundos AGORA',
      delay: 3,
    },
    {
      message: 'Feche um dos olhos por 3 segundos AGORA',
      delay: 3,
    },
  ];
  const framesArray = [0, 3, 3];
  const totalFrames = messagesArray.length;

  const getFrame = useCallback(async () => {
    const croppedImage = (canvasRef as any).current.toDataURL(
      'image/jpeg',
      0.9
    );

    setFieldValue(`${name}.files`, [...values[name].files, croppedImage]);
  }, [frameIndex]);

  const handleSMS = async () => {
    setSended(true);
    // await postSmsSelfieServices(accountId);
  };

  const main = useCallback(async () => {
    if (frameIndex <= totalFrames) {
      const screenshot =
        cameraRef.current && (cameraRef as any)?.current.getScreenshot();

      if (screenshot) {
        await getFullFaceDescription(screenshot).then(async (fullDesc: any) => {
          if (fullDesc) {
            const face_box = fullDesc._box;
            if (face_box._width < faceBounds && face_box._height < faceBounds) {
              setDetectMessage('Aproxime seu rosto...');
              setTimeout(main, 1000);
            } else {
              setDetectMessage('');
              await image64toCanvasRef(canvasRef.current, screenshot, face_box);

              const percentage = (frameIndex * 100) / totalFrames;
              setProgress(percentage);

              if (frameIndex === totalFrames) {
                setDetectMessage('');

                dispatchRedux.servicePostSelfie(values[name].files);
              } else {
                const currentMessage = messagesArray[frameIndex];
                const currentFrameDelay = framesArray[frameIndex];

                setDetectMessage(currentMessage.message);

                setTimeout(getFrame, currentFrameDelay * 1000);

                setTimeout(() => {
                  setFrameIndex(frameIndex + 1);
                }, currentMessage.delay * 1000);
              }
            }
          } else {
            setDetectMessage('Aguarde, detectando seu rosto...');
            setTimeout(main, 1000);
          }
        });
      } else {
        setDetectMessage('Aguarde, detectando seu rosto...');
        setTimeout(main, 1000);
      }
    }
  }, [frameIndex, getFrame]);

  useEffect(() => {
    if (!modelsLoaded) {
      (async () => {
        await loadModels();
        setDetectMessage('Aguarde, detectando seu rosto...');
        setModelsLoaded(true);
      })();
    } else {
      main();
    }
  }, [main, modelsLoaded]);

  const videoConstraints = {
    facingMode: 'user',
  };

  // const selectorCreditorRedux = {
  //   data: useSelector(creditorDuck.Selectors.data),
  // };

  return (
    <Grid container spacing={3}>
      {withoutWebcam && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Não conseguimos acessar uma câmera para tirar sua selfie.
              </Typography>
            </Grid>

            <Grid item xs={12} mt={4}>
              <Button
                fullWidth
                onClick={() =>
                  ((window as any).location.href =
                    process.env.REACT_APP_APP_URL)
                }
                variant="outlined"
                color="primary"
              >
                Concluir
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      {!withoutWebcam && (
        <>
          <Grid item xs={12}>
            <ContainerWebcam>
              <div className="cameraContainer">
                <div className={`cameraOverlay ${!detectMessage && 'off'}`}>
                  <span>{detectMessage}</span>
                </div>
                <div
                  className={`loadingOverlay ${selectorRedux.loading && 'on'}`}
                >
                  <CircularProgress className="loadingLoader" />
                </div>
                <div className="progress" style={{ width: `${progress}%` }} />
                <Webcam
                  audio={false}
                  ref={cameraRef}
                  minScreenshotHeight={700}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  onUserMediaError={() => {
                    setWithoutWebcam(true);
                  }}
                />
                <canvas ref={canvasRef} id="canvas" className="hidden"></canvas>
              </div>
            </ContainerWebcam>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UploadSelfie;
