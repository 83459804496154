import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import initialState from './onboarding.initialState';
import { ACTION_TYPES, NAME } from './onboarding.constant';

const handleHelloWorld = (state, action) => {
  state.data = action.payload;
  state.loading = false;
  state.error = false;
};

const handleUpdateStep = (state, action) => {
  state.step = action.step;
};

const handleUpdateVariant = (state, action) => {
  state.variant = action.variant;
};

const handleUpdatePayload = (state, action) => {
  state.payload = action.payload;
};

const handleUpdateUser = (state, action) => {
  state.user = action.payload;
};

const handleUpdatePayloadDocuments = (state, action) => {
  state.payload.documents = Object.assign(
    {},
    state.payload.documents,
    action.payload
  );
};

const handleFetchStart = (state) => {
  state.data = initialState.data;
  state.error = initialState.error;
  state.loading = true;
};

const handleFetchError = (state, action) => {
  state.error = [true, action.payload];
  state.loading = false;
};

const handleFetchSuccess = (state, action) => {
  state.error = [false, null];
  state.loading = false;
  state.data = action.payload;
};

const handleResetState = () => initialState;

const handleResetVariant = (state) => {
  state.variant = initialState.variant;
};

const handleResetService = (state) => {
  state.data = initialState.data;
  state.error = initialState.error;
  state.loading = initialState.loading;
};

const reducer = createReducer(initialState, {
  [ACTION_TYPES.HELLO_WORLD]: handleHelloWorld,
  [ACTION_TYPES.UPDATE.STEP]: handleUpdateStep,
  [ACTION_TYPES.UPDATE.VARIANT]: handleUpdateVariant,
  [ACTION_TYPES.UPDATE.PAYLOAD]: handleUpdatePayload,
  [ACTION_TYPES.UPDATE.USER]: handleUpdateUser,
  [ACTION_TYPES.UPDATE.PAYLOAD_DOCUMENTS]: handleUpdatePayloadDocuments,
  [ACTION_TYPES.RESET.STATE]: handleResetState,
  [ACTION_TYPES.RESET.VARIANT]: handleResetVariant,
  [ACTION_TYPES.FETCH.START]: handleFetchStart,
  [ACTION_TYPES.FETCH.ERROR]: handleFetchError,
  [ACTION_TYPES.FETCH.SUCCESS]: handleFetchSuccess,
  [ACTION_TYPES.RESET.SERVICE]: handleResetService,
});

export default reducer;
