import { takeLatest, select, call, put, delay } from 'redux-saga/effects';
import { v4 } from 'uuid';
import Cookies from 'js-cookie';
import pickBy from 'lodash/pickBy';

import toaster from '@giro/shared-store/toaster';
import auth from '@giro/shared-store/auth';

import viaCepService from '@giro/shared-services/viaCep.service';

import {
  STEPS_ORDER,
  VARIANTS,
} from '@giro-onboarding/constants/steps.constant';

import patchApiOnboardingsService from '@giro/shared-services/onboarding/patchApiOnboardings.service';
import postApiOnboardingsConfirmationsService from '@giro/shared-services/onboarding/postApiOnboardingsConfirmations.service';
import patchApiOnboardingsConfirmationsService from '@giro/shared-services/onboarding/patchApiOnboardingsConfirmations.service';
import postApiOnboardingsService from '@giro/shared-services/onboarding/postApiOnboardings.service';
import getApiOnboardingsService from '@giro/shared-services/onboarding/getApiOnboardings.service';

import configs from '../configs';

import { ACTION_TYPES } from './onboarding.constant';
import * as selectors from './onboarding.selector';
import * as actions from './onboarding.action';

import { toBase64 } from '@giro-onboarding/utils/imageFns.util';

const getIndexCurrentIndex = function* () {
  const stepCurrent = yield select(selectors.selectStep);

  const stepCurrentIndex = Object.values(STEPS_ORDER).findIndex(
    (so) => so === stepCurrent
  );

  return stepCurrentIndex;
};

const handleStepNext = function* (action) {
  const { payload } = action;

  const stepCurrentIndex = yield call(getIndexCurrentIndex);
  const stepCurrentNext = STEPS_ORDER[stepCurrentIndex + 1];

  const payloadSelector = yield select(selectors.selectPayload);

  const newPayload = {
    ...(payloadSelector || {}),
    ...payload,
  };

  yield put(actions.updatePayload(newPayload));

  return yield put(actions.updateStep(stepCurrentNext));
};

const handleGetZipcode = function* (action) {
  const { payload } = action;

  const payloadSelector = yield select(selectors.selectPayload);

  try {
    yield put(actions.fetchStart());

    const [success, result] = yield call(viaCepService, payload);

    if (!success) {
      throw result;
    }

    const newPayload = {
      ...(payloadSelector || {}),
      uuid: v4(),
      zipcode: result?.cep,
      address: result?.logradouro,
      number: '',
      complement: '',
      neighborhood: result?.bairro,
      city: result?.localidade,
      state: result?.uf,
    };

    yield put(actions.fetchSuccess(result));

    yield put(actions.updatePayload(newPayload));
  } catch (e) {
    yield put(actions.fetchError(e));
  }
};

const handleServiceGetCode = function* (action) {
  const { payload } = action;

  const payloadState = yield select(selectors.selectPayload);

  const { phone, email } = payloadState || {};

  const payloadSend = {
    email,
    phone,
    type: payload,
  };

  if (payload === 'phone') {
    delete payloadSend.email;
  }

  if (payload === 'email') {
    delete payloadSend.phone;
  }

  yield call(postApiOnboardingsConfirmationsService, payloadSend);
};

const handleServicePatchCode = function* (action) {
  const { payload, code } = action;

  const payloadState = yield select(selectors.selectPayload);

  const { phone, email } = payloadState || {};

  const payloadSend = {
    phone,
    email,
    type: payload,
    token: code,
  };

  if (payload === 'phone') {
    delete payloadSend.email;
  }

  if (payload === 'email') {
    delete payloadSend.phone;
  }

  yield put(actions.fetchStart());

  const [success, { isValid, token }] = yield call(async () => {
    const result = await patchApiOnboardingsConfirmationsService(payloadSend);

    return result;
  });

  yield put(actions.fetchSuccess({}));

  if (isValid) {
    yield put(auth.action.setToken(token));

    yield put(actions.stepNext());
  }

  if (!isValid) {
    yield put(
      toaster.action.show({
        message: 'Código inválido',
        variant: 'error',
      })
    );
  }
};

const handleServicePost = function* () {
  const payload = yield select(selectors.selectPayload);
  const mode = yield select(configs.selector.selectMode);
  const configsState = yield select(configs.selector.selectState);

  yield put(actions.fetchStart());

  const resp = {
    name: payload.name,
    email: payload.email,
    document_number: payload.document,
    phone_number: payload.phone,
    password: payload.password,
    password_confirmation: payload.confirmPassword,
    accept: true,
    onboarding_reference: Cookies.get(
      process.env.REACT_APP_COOKIE_KEY_RESERVE_ID
    ),
    integration_id: configsState.integration_id,
    address: {
      street: payload.address,
      state: payload.state,
      country: 'BR',
      countryCode: '55',
      neighborhood: payload.neighborhood,
      city: payload.city,
      postalCode: payload.zipcode,
      number: payload.number,
      complement: payload.complement,
    },
  };

  if (mode === 'sso') {
    delete resp.password;
    delete resp.password_confirmation;
  }

  const [success, result] = yield call(async () => {
    const response = await postApiOnboardingsService(pickBy(resp) as any);

    return response;
  });

  if (success) {
    yield put(auth.action.setToken(result?.token));

    yield put(actions.fetchSuccess(result));

    yield put(actions.updateVariant(VARIANTS.FINISH.SUCCESS));

    yield call(() => {
      Cookies.remove(process.env.REACT_APP_COOKIE_KEY_RESERVE_ID, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
    });
  }

  if (!success) {
    yield put(actions.fetchError(result));
    yield put(
      toaster.action.show({
        message: result?.message,
        variant: 'error',
      })
    );
    yield put(actions.updateVariant(VARIANTS.FINISH.ERROR));
  }
};

const handleServicePostDocuments = function* () {
  const payload = yield select(selectors.selectPayload);
  const documents = payload?.documents || {};
  const configsState = yield select(configs.selector.selectState);

  const files = Object.values(documents);

  yield put(actions.fetchStart());

  const [success, resultRequest] = yield call(async () => {
    const documents = await Promise.all(
      files.map(async (f: any) => {
        const base64File = await toBase64(f.file);

        return {
          base64: base64File.split(',')[1],
          type: 'document',
        };
      })
    );

    const [success, result] = await patchApiOnboardingsService({
      document_number: payload.document,
      phone_number: payload.phone,
      integration_id: configsState.integration_id,
      documents: documents as any,
      onboarding_reference: Cookies.get(
        process.env.REACT_APP_COOKIE_KEY_RESERVE_ID
      ),
    });

    return [success, result];
  });

  yield put(actions.fetchSuccess({}));

  if (success) {
    yield put(auth.action.setToken(resultRequest.token));
    yield put(actions.stepNext());
  }
};

const handleServicePostSelfie = function* (action) {
  const { payload } = action;

  const payloadState = yield select(selectors.selectPayload);
  const configsState = yield select(configs.selector.selectState);

  yield put(actions.fetchStart());

  const [success, resultRequest] = yield call(async () => {
    const documents = await Promise.all(
      payload.map(async (f: any) => {
        return {
          base64: f.split(',')[1],
          type: 'selfie',
        };
      })
    );

    const [success, result] = await patchApiOnboardingsService({
      integration_id: configsState.integration_id,
      document_number: payloadState.document,
      phone_number: payloadState.phone,
      documents: documents as any,
      onboarding_reference: Cookies.get(
        process.env.REACT_APP_COOKIE_KEY_RESERVE_ID
      ),
    });

    return [success, result];
  });

  yield put(actions.fetchSuccess({}));

  if (success) {
    yield put(auth.action.setToken(resultRequest.token));
    yield put(actions.stepNext());
  }
};

const handleConfigSelectMode = function* () {
  yield put(actions.resetState());
};

const handleGetService = function* () {
  yield put(actions.fetchStart());

  const [success, result] = yield call(getApiOnboardingsService);

  if (success) {
    yield put(actions.updateUser(result));
    yield put(actions.fetchSuccess({}));
  }
};

function* watch() {
  yield takeLatest(ACTION_TYPES.SERVICE.GET, handleGetService);
  yield takeLatest(ACTION_TYPES.STEP.NEXT, handleStepNext);
  yield takeLatest(ACTION_TYPES.SERVICE.GET_ZIPCODE, handleGetZipcode);
  yield takeLatest(
    ACTION_TYPES.SERVICE.POST_DOCUMENTS,
    handleServicePostDocuments
  );
  yield takeLatest(ACTION_TYPES.SERVICE.POST_SELFIE, handleServicePostSelfie);
  yield takeLatest(ACTION_TYPES.SERVICE.POST, handleServicePost);
  yield takeLatest(ACTION_TYPES.SERVICE.GET_CODE, handleServiceGetCode);
  yield takeLatest(ACTION_TYPES.SERVICE.PATCH_CODE, handleServicePatchCode);
}

export default {
  watch,
};
