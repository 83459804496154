import reducer from './configs.reducer';
import saga from './configs.saga';
import * as action from './configs.action';
import * as selector from './configs.selector';
import * as constant from './configs.constant';
import initialState from './configs.initialState';

export default {
  reducer,
  saga,
  action,
  constant,
  selector,
  initialState,
  name: constant.NAME,
};
