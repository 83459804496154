import { Typography } from '@mui/material';

const STEPS = {
  TERM: 'TERM',
  LEAD: 'LEAD',
  DOCUMENTS: 'DOCUMENTS',
  LIVENESS: 'LIVENESS',
  ADDRESS: 'ADDRESS',
  VALIDATION_PHONE: 'VALIDATION_PHONE',
  VALIDATION_EMAIL: 'VALIDATION_EMAIL',
  PASSWORD: 'PASSWORD',
  FINISH: 'FINISH',
};

export const STEPS_ORDER = Object.values(STEPS).reduce(
  (acc, curr, index) => ({
    ...acc,
    [index]: curr,
  }),
  {}
);

export const VARIANTS = {
  [STEPS.DOCUMENTS]: {
    INITIAL: 'INITIAL',
    RG: 'RG',
    CNH: 'CNH',
  },
  [STEPS.LIVENESS]: {
    INITIAL: 'INITIAL',
    TAKING: 'TAKING',
    FINISH: 'FINISH',
  },
  [STEPS.VALIDATION_PHONE]: {
    INITIAL: 'INITIAL',
    SENDED: 'SENDED',
  },
  [STEPS.VALIDATION_EMAIL]: {
    INITIAL: 'INITIAL',
    SENDED: 'SENDED',
  },
  [STEPS.FINISH]: {
    DEFAULT: 'DEFAULT',
    TERM: 'TERM',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    PROCESSING: 'PROCESSING',
    REPROVED: 'REPROVED',
  },
};

const propsTitle: any = {
  fontWeight: 'normal',
  variant: 'h5',
};

const propsStrongTitle: any = {
  color: 'secondary.main',
  component: 'strong',
};

export const LABELS = {
  [STEPS.TERM]: (
    <Typography {...propsTitle}>
      Leia o{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        termo de uso
      </Typography>
    </Typography>
  ),
  [STEPS.LEAD]: (
    <Typography {...propsTitle}>
      Informe seus{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        dados pessoais
      </Typography>
    </Typography>
  ),
  [STEPS.DOCUMENTS]: (
    <Typography {...propsTitle}>
      Envie seus{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        documentos
      </Typography>
    </Typography>
  ),
  [STEPS.LIVENESS]: (
    <Typography {...propsTitle}>
      Chegou a hora de nos enviar uma{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        selfie!
      </Typography>
    </Typography>
  ),
  [STEPS.ADDRESS]: (
    <Typography {...propsTitle}>
      Informe seu{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        endereço
      </Typography>
    </Typography>
  ),
  [STEPS.VALIDATION_PHONE]: (
    <Typography {...propsTitle}>
      Valide seu{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        celular
      </Typography>
    </Typography>
  ),
  [STEPS.VALIDATION_EMAIL]: (
    <Typography {...propsTitle}>
      Valide seu{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        email
      </Typography>
    </Typography>
  ),
  [STEPS.PASSWORD]: (
    <Typography fontWeight="light" variant="h5">
      Atualize sua{' '}
      <Typography
        component="strong"
        color="secondary.main"
        fontWeight="light"
        variant="h5"
      >
        senha
      </Typography>
    </Typography>
  ),
  [STEPS.FINISH]: (
    <Typography {...propsTitle}>
      Cadastro{' '}
      <Typography {...propsStrongTitle} {...propsTitle}>
        realizado
      </Typography>
    </Typography>
  ),
};

export default STEPS;
