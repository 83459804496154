import React from 'react';
import {
  Stack,
  TextField as TextFieldMUI,
  Box,
  MenuItem,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow, get } from 'lodash';
import { Formik, Field } from 'formik';
import { TextField } from 'formik-mui';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/lab/LoadingButton';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';

import states from '@giro/shared-constants/states.constant';
import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldZipcode from '@giro/shared-components/Fields/FieldZipcode.component';
import FieldNumber from '@giro/shared-components/Fields/FieldNumber.component';

import onboarding from '@giro-onboarding/store/onboarding';
import configs from '@giro-onboarding/store/configs';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const OnboardingStepDataComponent = () => {
  const auth = useAuthHook();

  const formikRef: any = React.useRef(null);

  const [noNumber, setNoNumber] = React.useState(false);

  const dispatch = useDispatch();

  const dispatchRedux = {
    stepNext: flow(onboarding.action.stepNext, dispatch),
    serviceGetZipcode: flow(onboarding.action.serviceGetZipcode, dispatch),
    resetService: flow(onboarding.action.resetService, dispatch),
  };

  const selectorRedux = {
    payload: useSelector(onboarding.selector.selectPayload),
    loading: useSelector(onboarding.selector.selectLoading),
    configs: useSelector(configs.selector.selectState),
  };

  React.useEffect(() => {
    const containInSteps = Array.from(selectorRedux.configs?.steps).includes(
      'require_bgc'
    );

    const userChecked = auth.user?.user?.checked_bgc;

    if (!containInSteps || userChecked) {
      dispatchRedux.stepNext();
    }
  }, []);

  React.useEffect(() => {
    return () => {
      dispatchRedux.resetService();
    };
  }, []);

  const initialValues = {
    zipcode: selectorRedux.payload.zipcode,
    address: selectorRedux.payload.address,
    number: selectorRedux.payload.number,
    complement: selectorRedux.payload.complement,
    neighborhood: selectorRedux.payload.neighborhood,
    city: selectorRedux.payload.city,
    state: selectorRedux.payload.state,
  };

  const schema = Yup.object({
    zipcode: Yup.string().required(messages.REQUIRED).min(8, messages.MIN),
    address: Yup.string().required(messages.REQUIRED),
    number: Yup.string().test('check', messages.REQUIRED, (val) => {
      if (noNumber) {
        return true;
      }

      return !!val;
    }),
    complement: Yup.string(),
    neighborhood: Yup.string().required(messages.REQUIRED),
    city: Yup.string().required(messages.REQUIRED),
    state: Yup.string().required(messages.REQUIRED),
  });

  const handleSubmit = async (values) => {
    dispatchRedux.stepNext(values);

    return true;
  };

  const handleGetZipCode = ({ value, errors }) => {
    const hasError = !!get(errors, 'zipcode', null);

    if (hasError) {
      return false;
    }

    dispatchRedux.serviceGetZipcode(value);
  };

  const fields = ({ errors }) => (
    <Stack gap={3}>
      <FieldZipcode
        name="zipcode"
        onBlur={(value) => handleGetZipCode({ value, errors })}
        disabled={selectorRedux.loading}
      />
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: '1fr 150px',
        }}
        gap={3}
      >
        <Field
          name="address"
          component={TextField}
          label="Endereço"
          disabled={selectorRedux.loading}
        />
        <Box>
          <FieldNumber
            name="number"
            label="Número"
            type="tel"
            disabled={selectorRedux.loading || noNumber}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={noNumber}
                  onChange={({ target: { checked } }) => {
                    setNoNumber(checked);

                    setTimeout(() => {
                      formikRef.current?.setFieldValue('number', '');
                      formikRef.current?.validateForm();
                    });
                  }}
                />
              }
              label={<Typography fontSize="12px">Sem número</Typography>}
            />
          </FormGroup>
        </Box>
      </Box>
      <Field
        name="complement"
        component={TextField}
        label="Complemento"
        disabled={selectorRedux.loading}
      />
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: '1fr 1fr',
        }}
        gap={3}
      >
        <Field
          name="neighborhood"
          component={TextField}
          label="Bairro"
          disabled={selectorRedux.loading}
        />
        <Field
          name="city"
          component={TextField}
          label="Cidade"
          disabled={selectorRedux.loading}
        />
      </Box>
      <Field
        component={TextField}
        select
        name="state"
        fullWidth
        label="Estado"
        disabled={selectorRedux.loading}
      >
        {states.map((s) => (
          <MenuItem key={s.id_uf} value={s.sigla}>
            {s.nome}
          </MenuItem>
        ))}
      </Field>
    </Stack>
  );

  const actions = ({ errors, submitForm }) => (
    <Stack>
      <Button
        loading={selectorRedux.loading}
        onClick={submitForm}
        variant="contained"
        disabled={Object.keys(errors).length > 0}
      >
        Continuar
      </Button>
    </Stack>
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      validationSchema={schema}
      key={selectorRedux.payload.uuid}
      innerRef={formikRef}
    >
      {({ errors, submitForm }) => (
        <Stack gap={6}>
          {fields({ errors })}
          {actions({ errors, submitForm })}
        </Stack>
      )}
    </Formik>
  );
};

export default OnboardingStepDataComponent;
