import React from 'react';
import { flow } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import messages from '@giro/shared-constants/messagesSchema.constant';

import FieldPhone from '@giro/shared-components/Fields/FieldPhone.component';

import dialogChangePhone from '@giro-onboarding/store/dialogs/dialogChangePhone';
import onboarding from '@giro-onboarding/store/onboarding';

const DialogChangePhoneComponent = () => {
  const dispatch = useDispatch();

  const dispatchRedux = {
    close: flow(dialogChangePhone.action.close, dispatch),
  };

  const dispatchOnboarding = {
    updatePayload: flow(onboarding.action.updatePayload, dispatch),
    serviceGetCode: flow(onboarding.action.serviceGetCode, dispatch),
  };

  const selectorRedux = {
    visible: useSelector(dialogChangePhone.selector.selectVisible),
  };

  const selectorOnboardingRedux = {
    payload: useSelector(onboarding.selector.selectPayload),
  };

  const handleClose = () => {
    dispatchRedux.close();
  };

  const initialValues = {
    phone: '',
  };

  const schema = Yup.object({
    phone: Yup.string().required(messages.REQUIRED).min(10, messages.MIN),
  });

  const handleSubmit = async (values) => {
    dispatchOnboarding.updatePayload({
      ...selectorOnboardingRedux.payload,
      ...values,
    });

    dispatchOnboarding.serviceGetCode('phone');

    dispatchRedux.close();

    return true;
  };

  return (
    <Dialog open={selectorRedux.visible || false} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnMount
      >
        {({ errors, submitForm }) => (
          <Form>
            <DialogTitle>Atualizar o celular</DialogTitle>
            <DialogContent>
              <Box
                mt={3}
                width="100%"
                minWidth={{
                  xs: 0,
                  md: 300,
                }}
              >
                <FieldPhone type="tel" name="phone" />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button
                variant="contained"
                onClick={submitForm}
                disabled={Object.keys(errors).length > 0}
              >
                Atualizar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DialogChangePhoneComponent;
