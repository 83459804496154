const EXTERNAL_AUTH_ROUTES = {
  LOGIN: `${process.env.REACT_APP_AUTH_URL}/login`,
  CREATE_ACCOUNT: `${process.env.REACT_APP_AUTH_URL}/create-account`,
};

const EXTERNAL_ROUTES = {
  AUTH: EXTERNAL_AUTH_ROUTES,
};

const ONBOARDING = {
  ROOT: `/`,
  EXTERNAL: EXTERNAL_ROUTES,
};

export default ONBOARDING;
