import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  name: string;
  email: string;
  document_number: string;
  phone_number: string;
  password?: string;
  onboarding_reference?: string;
  integration_id: string;
  password_confirmation?: string;
  address: {
    street: string;
    state: string;
    country: string;
    countryCode: string;
    neighborhood: string;
    city: string;
    postalCode: string;
    number: string;
    complement: string;
  };
  accept: boolean;
};

export default async function postApiOnboardingsService(body: BodyType) {
  return fetch(`onboardings`, {
    method: 'POST',
    ms: 'ONBOARDING',
    auth: true,
    body: JSON.stringify(body),
  });
}
