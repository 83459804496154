import fetch from '@giro/shared-utils/fetch.util';

type BodyType = {
  integration_id: string;
  document_number: string;
  phone_number: string;
  onboarding_reference: string;
  documents: Array<{
    type: 'selfie' | 'document';
    base64: string;
  }>;
};

export default async function patchApiOnboardingsService(body: BodyType) {
  return fetch(`onboardings`, {
    method: 'PATCH',
    ms: 'ONBOARDING',
    auth: true,
    body: JSON.stringify(body),
  });
}
