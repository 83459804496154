export const NAME = 'configs';

const CONTEXT = 'CONFIGS';

const HELLO_WORLD = `${CONTEXT}::HELLO_WORLD`;

const SELECT_MODE = `${CONTEXT}::SELECT::MODE`;

const UPDATE_STEPS = `${CONTEXT}::UPDATE::STEPS`;

const UPDATE = {
  STEPS: UPDATE_STEPS,
};

const SELECT = {
  MODE: SELECT_MODE,
};

export const ACTION_TYPES = {
  HELLO_WORLD,
  SELECT,
  UPDATE,
};
