import reducer from './dialogChangePhone.reducer';
import * as action from './dialogChangePhone.action';
import * as selector from './dialogChangePhone.selector';
import * as constant from './dialogChangePhone.constant';
import initialState from './dialogChangePhone.initialState';
import subscriber from './dialogChangePhone.subscriber';
import saga from './dialogChangePhone.saga';

export default {
  reducer,
  action,
  constant,
  selector,
  initialState,
  subscriber,
  saga,
  name: constant.NAME,
};
