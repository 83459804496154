import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Box, Typography, Stack } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import * as Yup from 'yup';

import UPLOAD_STEPS from '@giro-onboarding/constants/uploadSteps.constants';
import { VARIANTS } from '@giro-onboarding/constants/steps.constant';

import onboarding from '@giro-onboarding/store/onboarding';
import configs from '@giro-onboarding/store/configs';

import FieldUploadPhoto from '../Fields/FieldUploadPhoto.component';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const OnboardingStepDocumentsComponent = () => {
  const auth = useAuthHook();

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateVariant: flow(onboarding.action.updateVariant, dispatch),
    stepNext: flow(onboarding.action.stepNext, dispatch),
    resetVariant: flow(onboarding.action.resetVariant, dispatch),
    servicePostDocuments: flow(
      onboarding.action.servicePostDocuments,
      dispatch
    ),
  };

  const selectorRedux = {
    variant: useSelector(onboarding.selector.selectVariant),
    loading: useSelector(onboarding.selector.selectLoading),
    payload: useSelector(onboarding.selector.selectPayload),
    configs: useSelector(configs.selector.selectState),
  };

  React.useEffect(() => {
    const containInSteps = Array.from(selectorRedux.configs?.steps).includes(
      'require_document'
    );

    const userChecked = auth.user?.user?.checked_document;

    if (!containInSteps || userChecked) {
      dispatchRedux.stepNext();
    }
  }, []);

  React.useEffect(() => {
    const BLACKLIST = [VARIANTS.DOCUMENTS.RG, VARIANTS.DOCUMENTS.CNH];

    if (!BLACKLIST.includes(selectorRedux.variant)) {
      dispatchRedux.updateVariant(VARIANTS.DOCUMENTS.INITIAL);
    }
  }, []);

  const handleNextStep = () => dispatchRedux.servicePostDocuments();

  const currentName = selectorRedux.payload?.name;

  const action = ({ errors }) => (
    <Stack>
      <Button
        loading={selectorRedux.loading}
        onClick={handleNextStep}
        variant="contained"
        disabled={Object.keys(errors).length > 0}
      >
        Continuar
      </Button>
    </Stack>
  );

  const initial = (
    <Stack gap={6}>
      <Stack gap={3}>
        <Typography>
          <strong>{currentName}</strong>, precisamos de uma foto do seu
          documento pessoal.
        </Typography>
        <Typography>
          Coloque sobre uma superfície plana em lugar bem iluminado e fique
          atento aos reflexos.
        </Typography>
        <Typography>Selecione o tipo de documento abaixo</Typography>
      </Stack>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => dispatchRedux.updateVariant(VARIANTS.DOCUMENTS.CNH)}
        >
          CNH
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => dispatchRedux.updateVariant(VARIANTS.DOCUMENTS.RG)}
        >
          RG
        </Button>
      </Box>
    </Stack>
  );

  const initialValueRg = {
    documents: {
      rg_frente: selectorRedux?.payload?.documents?.rg_frente || null,
      rg_verso: selectorRedux?.payload?.documents?.rg_verso || null,
    },
  };

  const schemaRg = Yup.object({
    documents: Yup.object({
      rg_frente: Yup.object({
        upload: Yup.string().oneOf([UPLOAD_STEPS.SUCCESS], ''),
      }),
      rg_verso: Yup.object({
        upload: Yup.string().oneOf([UPLOAD_STEPS.SUCCESS], ''),
      }),
    }),
  });

  const rg = (
    <Formik
      initialValues={initialValueRg}
      validationSchema={schemaRg}
      validateOnMount
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ errors }) => (
        <Stack gap={6}>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: '1fr',
              md: '1fr 1fr',
            }}
            gap={4}
          >
            <FieldUploadPhoto
              field="rg_frente"
              name="documents.rg_frente"
              label="RG Frente"
              type="document"
            />
            <FieldUploadPhoto
              field="rg_verso"
              name="documents.rg_verso"
              label="RG Verso"
              type="document"
            />
          </Box>

          {action({ errors })}
        </Stack>
      )}
    </Formik>
  );

  const initialValueCnh = {
    documents: {
      cnh: selectorRedux?.payload?.documents?.cnh || null,
    },
  };

  const schemaCnh = Yup.object({
    documents: Yup.object({
      cnh: Yup.object({
        upload: Yup.string().oneOf([UPLOAD_STEPS.SUCCESS], ''),
      }),
    }),
  });

  const cnh = (
    <Formik
      initialValues={initialValueCnh}
      validationSchema={schemaCnh}
      validateOnMount
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ errors }) => (
        <Stack gap={6}>
          <Box display="grid" gridTemplateColumns="1fr" gap={4}>
            <FieldUploadPhoto
              field="cnh"
              name="documents.cnh"
              label="CNH"
              type="document"
            />
          </Box>

          {action({ errors })}
        </Stack>
      )}
    </Formik>
  );

  return (
    <>
      {selectorRedux.variant === VARIANTS.DOCUMENTS.INITIAL && initial}
      {selectorRedux.variant === VARIANTS.DOCUMENTS.RG && rg}
      {selectorRedux.variant === VARIANTS.DOCUMENTS.CNH && cnh}
    </>
  );
};

export default OnboardingStepDocumentsComponent;
