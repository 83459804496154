import React from 'react';
import { flow, get } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';

import { toBase64 } from '../../utils/imageFns.util';

import patchApiOnboardingsService from '@giro/shared-services/onboarding/patchApiOnboardings.service';

import UPLOAD_STEPS from '@giro-onboarding/constants/uploadSteps.constants';

import onboarding from '@giro-onboarding/store/onboarding';

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1200,
      1200,
      'png',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file'
    );
  });

const FieldUploadPhotoComponent = (props: {
  label: string;
  name: string;
  field: string;
  accept?: string;
  type: 'selfie' | 'document';
}) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();

  const dispatchRedux = {
    updatePayloadDocuments: flow(
      onboarding.action.updatePayloadDocuments,
      dispatch
    ),
  };

  const formik = useFormikContext();

  const value = get(formik.values, props.name);

  const hasValue = !!value;

  const upload = hasValue ? value.upload : UPLOAD_STEPS.PENDING;

  const onDrop = React.useCallback(async (files: any) => {
    const imgs = await Promise.all(
      files.map(async (img: any) => {
        const file = img.type.includes('image') ? await resizeFile(img) : img;

        const obj = {
          field: props.name,
          upload: UPLOAD_STEPS.SUCCESS,
          url: URL.createObjectURL(file),
          file,
        };

        dispatchRedux.updatePayloadDocuments({
          [props.field]: obj,
        });

        return obj;
      })
    );
  }, []);

  const handleRemove = () => {
    dispatchRedux.updatePayloadDocuments({
      [props.field]: null,
    });
  };

  const dropzone = useDropzone({
    onDrop,
    multiple: false,
    accept: props.accept as any,
  });

  const empty = (
    <Box
      p={4}
      border="1px solid transparent"
      borderColor="gray.200"
      borderRadius={2}
      {...dropzone.getRootProps()}
    >
      <Stack gap={0}>
        <Box alignSelf="center">
          <CloudUploadIcon sx={{ color: 'gray.400', fontSize: '40px' }} />
        </Box>
        <Stack gap={2}>
          {!isMobile && (
            <Stack gap={0}>
              <Typography
                variant="subtitle2"
                textAlign="center"
                color="gray.400"
              >
                Arraste para cá
              </Typography>
              <Typography
                variant="subtitle2"
                textAlign="center"
                color="gray.400"
              >
                Ou, se preferir...
              </Typography>
            </Stack>
          )}
          <Button variant="outlined" fullWidth size="small">
            Clique aqui
          </Button>

          <input
            {...dropzone.getInputProps()}
            accept="image/*"
            capture
            style={{ display: 'none' }}
          />
        </Stack>
      </Stack>
    </Box>
  );

  const full = (
    <Box
      p={4}
      border="1px solid transparent"
      borderColor="gray.200"
      borderRadius={2}
      position="relative"
    >
      <Box position="relative" textAlign="center">
        {(upload === UPLOAD_STEPS.PENDING || upload === UPLOAD_STEPS.ERROR) && (
          <Box
            width="100%"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            height="100%"
            bgcolor="#ffffffe0"
          />
        )}

        {upload === UPLOAD_STEPS.UPLOADING && (
          <Box
            width="100%"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            height="100%"
            bgcolor="#ffffffe0"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}

        <img src={value?.url} style={{ maxHeight: 300, maxWidth: '100%' }} />
      </Box>

      {![
        upload === UPLOAD_STEPS.PENDING,
        upload === UPLOAD_STEPS.UPLOADING,
      ].some((e) => !!e) && (
        <Box position="absolute" top={0} right={0}>
          <IconButton size="small" onClick={handleRemove}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );

  return (
    <Stack gap={2}>
      <Typography fontWeight="bold">{props.label}</Typography>

      {!hasValue && empty}
      {hasValue && full}
    </Stack>
  );
};

export default FieldUploadPhotoComponent;
