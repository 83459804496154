import { takeLatest, select, call, put, delay } from 'redux-saga/effects';

import { ACTION_TYPES } from './configs.constant';
import * as selectors from './configs.selector';
import * as actions from './configs.action';

function* watch() {}

export default {
  watch,
};
