const initialState = {
  data: null,
  loading: null,
  error: null,
  filters: {
    offset: 0,
    limit: 99999,
  },
};

export default initialState;
