import Cookies from 'js-cookie';

const initialState = {
  mode: 'default',
  steps: null,
  integration_id:
    Cookies.get(process.env.REACT_APP_COOKIE_KEY_INTEGRATION_ID) ||
    process.env.REACT_APP_INTEGRATION_ID_DEFAULT,
};

export default initialState;
