import { combineReducers } from 'redux';

import dialogChangePhone from './dialogChangePhone';
import dialogChangeEmail from './dialogChangeEmail';

const reducer = combineReducers({
  [dialogChangePhone.name]: dialogChangePhone.reducer,
  [dialogChangeEmail.name]: dialogChangeEmail.reducer,
});

export default reducer;
