import { createReducer } from '@reduxjs/toolkit';

import initialState from './configs.initialState';
import { ACTION_TYPES } from './configs.constant';

const handleHelloWorld = (state, action) => {
  state.data = action.payload;
  state.loading = false;
  state.error = false;
};

const handleSelectMode = (state, action) => {
  state.mode = action.payload;
};

const handleUpdateSteps = (state, action) => {
  state.steps = action.payload;
};

const reducer = createReducer(initialState, {
  [ACTION_TYPES.HELLO_WORLD]: handleHelloWorld,
  [ACTION_TYPES.SELECT.MODE]: handleSelectMode,
  [ACTION_TYPES.UPDATE.STEPS]: handleUpdateSteps,
});

export default reducer;
