export const NAME = 'dialogChangePhone';

const CONTEXT = 'ONBOARDING::DIALOGS::CHANGE_PHONE';

const RESET = `${CONTEXT}::RESET`;

const SET_OPEN = `${CONTEXT}::SET::OPEN`;
const SET_CLOSE = `${CONTEXT}::SET::CLOSE`;

const SET = {
  OPEN: SET_OPEN,
  CLOSE: SET_CLOSE,
};

export const ACTION_TYPES = {
  SET,
  RESET,
};
