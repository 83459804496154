import { all } from 'redux-saga/effects';

import auth from '@giro/shared-store/auth';

import onboardingReducer from '@giro-onboarding/store/onboarding';
import dialogsReducer from '@giro-onboarding/store/dialogs';
import configsReducer from '@giro-onboarding/store/configs';
import appsReducer from '@giro-onboarding/store/apps';

function* rootSaga() {
  yield all([
    auth.saga.watch(),
    appsReducer.saga.watch(),
    configsReducer.saga.watch(),
    dialogsReducer.saga.watch(),
    onboardingReducer.saga.watch(),
  ]);
}

export default rootSaga;
