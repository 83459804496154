import { ACTION_TYPES } from './configs.constant';

export const helloWorld = (payload) => ({
  type: ACTION_TYPES.HELLO_WORLD,
  payload,
});

export const selectMode = (mode: 'default' | 'sso') => ({
  type: ACTION_TYPES.SELECT.MODE,
  payload: mode,
});

export const updateSteps = (payload) => ({
  type: ACTION_TYPES.UPDATE.STEPS,
  payload,
});
