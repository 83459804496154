import { useSelector } from 'react-redux';

import configs from '@giro-onboarding/store/configs';

const useIsSSOModeHook = () => {
  const selectorRedux = {
    mode: useSelector(configs.selector.selectMode),
  };

  const isSSO = selectorRedux.mode === 'sso';

  return isSSO;
};

export default useIsSSOModeHook;
