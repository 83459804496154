import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';
import { Box, Typography, Stack, TextField } from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import { useTimer } from 'react-timer-hook';

import { VARIANTS } from '@giro-onboarding/constants/steps.constant';

import onboarding from '@giro-onboarding/store/onboarding';
import dialogChangeEmail from '../../store/dialogs/dialogChangeEmail';

import InputCode from '@giro-onboarding/components/InputCode.component';

import configs from '@giro-onboarding/store/configs';

import useAuthHook from '@giro/shared-hooks/useAuth.hook';

const OnboardingStepValidationEmailComponent = () => {
  const auth = useAuthHook();

  const { seconds, restart, isRunning } = useTimer({
    expiryTimestamp: new Date(),
  });

  const dispatch = useDispatch();

  const dispatchRedux = {
    updateVariant: flow(onboarding.action.updateVariant, dispatch),
    stepNext: flow(onboarding.action.stepNext, dispatch),
    resetVariant: flow(onboarding.action.resetVariant, dispatch),
    serviceGetCode: flow(onboarding.action.serviceGetCode, dispatch),
    servicePatchCode: flow(onboarding.action.servicePatchCode, dispatch),
  };

  const dispatchDialogRedux = {
    open: flow(dialogChangeEmail.action.open, dispatch),
  };

  const selectorRedux = {
    variant: useSelector(onboarding.selector.selectVariant),
    loading: useSelector(onboarding.selector.selectLoading),
    payload: useSelector(onboarding.selector.selectPayload),
    user: useSelector(onboarding.selector.selectUser),
    configs: useSelector(configs.selector.selectState),
  };

  React.useEffect(() => {
    const containInSteps = Array.from(selectorRedux.configs?.steps).includes(
      'require_user'
    );

    const userChecked = auth.user?.user?.checked_email;

    if (!containInSteps || userChecked) {
      dispatchRedux.stepNext();
    }
  }, []);

  React.useEffect(() => {
    startTime();

    dispatchRedux.updateVariant(VARIANTS.VALIDATION_EMAIL.INITIAL);
  }, []);

  React.useEffect(() => {
    if (selectorRedux?.user?.checked_email) {
      dispatchRedux.stepNext();
    } else {
      dispatchRedux.serviceGetCode('email');
    }
  }, [selectorRedux?.user?.checked_email]);

  const startTime = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 59);
    restart(time);
  };

  const handleNextStep = (code) => {
    dispatchRedux.servicePatchCode('email', code);
  };

  const handleResendEmail = () => {
    startTime();

    dispatchRedux.serviceGetCode('email');
  };

  const currentEmail = selectorRedux.payload?.email;

  const initial = (
    <Stack gap={6}>
      <Stack gap={1}>
        <Typography>
          Precisamos confirmar que o email <strong>{currentEmail}</strong> é
          seu.
        </Typography>
        <Typography>
          Caso o email informado esteja errado,{' '}
          <a href="#" onClick={() => dispatchDialogRedux.open({})}>
            clique aqui
          </a>{' '}
          para edita-lo
        </Typography>
      </Stack>
      <Stack gap={2}>
        <InputCode
          onComplete={handleNextStep}
          disabled={selectorRedux.loading}
        />

        <Box>
          <Button onClick={handleResendEmail} disabled={isRunning}>
            Reenviar código {isRunning && `(${seconds})`}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );

  return (
    <Stack gap={6}>
      {selectorRedux.variant === VARIANTS.VALIDATION_EMAIL.INITIAL && initial}
    </Stack>
  );
};

export default OnboardingStepValidationEmailComponent;
