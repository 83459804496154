export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function base64StringtoFile(base64String, filename) {
  var arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function image64toCanvasRef(canvas, image64, pixelCrop) {
  return new Promise((resolve) => {
    canvas.width = pixelCrop._width;
    canvas.height = pixelCrop._height;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = image64;
    image.onload = function () {
      ctx.drawImage(
        image,
        pixelCrop._x,
        pixelCrop._y,
        pixelCrop._width,
        pixelCrop._height,
        0,
        0,
        pixelCrop._width,
        pixelCrop._height
      );
      resolve();
    };
  });
}
