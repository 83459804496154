import { isEmpty, isEqual, throttle } from 'lodash';

import * as selector from './onboarding.selector';
import * as action from './onboarding.action';

import UPLOAD_STEPS from '@giro-onboarding/constants/uploadSteps.constants';

let nextState = {};
let prevState = {};

const isStateEmpty = () => isEmpty(nextState);

const subscribe = ({ getState, dispatch }) => {
  nextState = getState();

  prevState = nextState;
};

export default {
  subscribe,
};
